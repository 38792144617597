<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle class="rcflex rcflex-btw row">
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.push('/medhistory/manage')"
    />
    История болезни
    <VaButton preset="primary" icon="qr_code_2" :size="36" @click="showQRModal=!showQRModal"/>
    </VaCardTitle>
  
    <VaCardContent>
      <VaAlert
            color="success"
            border="left"
            class="mb-4"
          >
            <VaCardContent class="rc-text-m">
              <div class="rcflex row">
                <div class="rcflex vcenter">
                  <VaChip v-if="!medhistory.accepted" size="small" :color="medhistory.closed ? 'warning' : 'success'">
                    {{ medhistory.closed ? 'История болезни закрыта ' + medhistory.closeDate : 'История болезни открыта' }}
                  </VaChip>
                  <VaChip v-else size="small" color="secondary">
                    История болезни в архиве с {{ medhistory.accepted_date }}
                  </VaChip>
                </div>
                <VaSpacer/>
                <div class="rcflex vcenter">
                  <VaButton v-if="medhistory.transfer && medhistory.transfer === userProfile.id"
                        class="mr-2" 
                        icon="person_check"
                        preset="primary"
                        color="success"
                        @click="acceptTransfer"
                        :loading="patchingMedhistory"
                        >
                        Принять пациента
                      </VaButton>
                  <VaDropdown trigger="click" placement="bottom-end">

                    <template #anchor>
                      <VaButton icon="menu" preset="primary" />
                    </template>

                    <VaDropdownContent >
                      
                      <VaButton
                        :disabled="!discharge.id"
                        class="mr-2 mb-2" 
                        icon="print" 
                        preset="primary" 
                        @click="print('print','dischargeEpicrisis')"
                        >
                        Выписной эпикриз
                      </VaButton>
                      <VaButton
                        :disabled="medhistory.closed || !iamOwner"
                        class="mr-2 mb-2" 
                        icon="print" 
                        preset="primary" 
                        @click="print('print','pharmacy')"
                        >
                        ВК по фармакотерапии
                      </VaButton>
                      <VaButton :disabled="medhistory.discharged || !iamOwner"
                        class="mr-2 mb-2" 
                        icon="moving_beds" 
                        preset="primary" 
                        @click="confirmDischarge()">
                        Выписать
                      </VaButton>
                      <VaButton
                        :disabled="!readyToPrint"
                        class="mr-2 mb-2" 
                        icon="print" 
                        preset="primary" 
                        @click="medhistoryPrintModal=!medhistoryPrintModal"
                        >
                        Печать истории болезни
                      </VaButton>
                      <VaButton 
                        v-if="iamOwner"
                        :disabled="medhistory.discharged || medhistory.closed"
                        class="mr-2 mb-2" 
                        icon="move_item" 
                        preset="primary" 
                        @click="transferModal=!transferModal">
                        Передать
                      </VaButton>
                      <VaButton
                        v-if="(((comments.length && iamOwner) || iamBoss) && !medhistory.accepted) || (medhistory.closed && iamOwner)"
                        preset="primary"
                        color="primary"
                        class="mr-2 mb-2 rc-flex-right"
                        icon="comment"
                        @click="commentModal=!commentModal"
                        id="commentButton"
                      >
                        Написать комментарий
                      </VaButton>
                      <VaButton v-if="medhistory.closed && iamBoss && !medhistory.accepted"
                        preset="primary"
                        color="primary"
                        class="mr-2 mb-2"
                        icon="assignment_return"
                        @click="openMedhistory()"
                      >
                        На доработку
                      </VaButton>
                      <VaButton v-if="!medhistory.closed && iamDoctor && iamOwner"
                        preset="primary"
                        color="success"
                        class="mr-2 mb-2"
                        icon="done"
                        @click="confirmCloseMedhistory()"
                      >
                        Закрыть историю
                      </VaButton>
                      <VaButton v-if="medhistory.closed && !medhistory.accepted && iamBoss"
                        preset="primary"
                        color="success"
                        class="mr-2 mb-2"
                        icon="done"
                        @click="confirmAcceptMedhistory()"
                      >
                        Принять
                      </VaButton>

                    </VaDropdownContent>

                  </VaDropdown>
                </div>
                <div>
              </div>
              </div>
                <p class="mb-1">Статус: {{ medhistory.closed ? (medhistory.accepted ? 'Принята' : 'На проверке') : 'Открыта' }}</p>
                <p class="mb-1">Признак оплаты:</p>
                <div class="rcflex vcenter">
                  <VaSwitch
                    :disabled="medhistory.closed"
                    v-model="delta.paid"
                    size="small"
                    class="rcflex mt-3 mb-3 mr-3"
                    @change="patchMedhistory({paid: delta.paid})"
                  />
                  <VaIcon
                    :name="delta.paid ? 'attach_money' : 'cruelty_free'"
                    :color="delta.paid ? 'success' : 'primary'"
                    class="w-full ml-2"
                  />
                  {{ delta.paid ? "Платный" : "КСГ"}}
                </div>
                <p class="mb-1">Анестезия:</p>
                <div class="rcflex vcenter">
                  <VaSwitch
                    :disabled="medhistory.closed || typeof(anexam.id) === 'number' || typeof(preop.id) === 'number'"
                    v-model="delta.localAnesthesia"
                    size="small"
                    class="rcflex mt-3 mb-3 mr-3"
                    @change="patchMedhistory({localAnesthesia: delta.localAnesthesia})"
                  />
                  <VaIcon
                    :name="delta.localAnesthesia ? 'syringe' : 'ecg'"
                    :color="delta.localAnesthesia ? 'success' : 'danger'"
                    class="w-full ml-2"
                  />
                  {{ delta.localAnesthesia ? "Местная" : "Общая"}}
                </div>
                <p class="mb-1 " style="line-height:1.5rem">Палата: 

                  <VaValue v-slot="v">
                    <input v-if="v.value" v-model="delta.room" style="padding:0 4px; width:48px">
                    <span v-else>
                      {{ delta.room }}
                    </span>

                    <VaButton v-if="v.value" icon="save" class="ml-2" preset="plain" size="medium" @click.before="v.value = !v.value" @click="patchMedhistory({room: delta.room})"/>
                    <VaButton :disabled="medhistory.closed" v-else icon="edit" class="ml-2" preset="plain" size="medium" @click="v.value = !v.value"/>
                  </VaValue>
                </p>
                <p class="mb-1">Дата и время поступления: {{ medhistory.openDate }}</p>
                <p class="mb-1" v-if="medhistory.discharged">Дата и время выписки: {{ medhistory.discharge_date }}</p>
                <p class="mb-1 ">Лечащий врач: <span class="rc-text-bold">{{ medhistory.doctor_full_name }}</span></p>
                <div v-if="medhistory.transfer" style="display:inline-block;">
                  <VaAlert
                    color="warning"
                    class="mb-6 mt-2"
                    style="max-width:max-content"
                  >
                    <template #icon>
                      <VaIcon name="person_alert" />
                    </template>
                    История болезни в состоянии передачи. Новый лечащий врач {{ medhistory.newdoctor_full_name }} еще не принял пациента.
                  </VaAlert>
                </div>
                <div v-if="!medhistory.closed && !iamOwner && !iamAnesthesist" style="display:inline-block;">
                  <VaAlert
                    color="info"
                    class="mb-6 mt-2"
                    style="max-width:max-content"
                  >
                    <template #icon>
                      <VaIcon name="info" />
                    </template>
                    Разрешен только просмотр. Изменения в этой истории болезни может сделать только лечащий врач
                  </VaAlert>
                </div>
                <div v-if="!medhistory.closed && !iamOwner && iamAnesthesist" style="display:inline-block;">
                  <VaAlert
                    color="info"
                    class="mb-6 mt-2"
                    style="max-width:max-content"
                  >
                    <template #icon>
                      <VaIcon name="info" />
                    </template>
                    Разрешен только просмотр истории болезни и создание и изменение осмотров анестезиолога
                  </VaAlert>
                </div>
                <div v-if="medhistory.closed" style="display:inline-block;">
                  <VaAlert
                    color="warning"
                    class="mb-6 mt-2"
                    style="max-width:max-content"
                  >
                    <template #icon>
                      <VaIcon name="info" />
                    </template>
                    Разрешен только просмотр
                  </VaAlert>
                </div>
            </VaCardContent>
            
          <VaCardTitle class="rc-text-m rcflex rcflex-btw row ">
              Пациент
            </VaCardTitle>
            
          <VaCollapse
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
            <div class="va-table-responsive">
              <PatientProfile :patient="patient" v-if="patient.passport"/>
            </div>
          </VaCollapse>
          <VaCollapse
            icon="radiology"
            class="min-w-96 mb-3 rc-text-m"
            :header="'Снимки (' + images.length + ')'"
          >
          <div v-if="images.length>0" v-for="item,index in images">
            
            <div class="rcflex row rc-image-wrapper pa-2 mb-4">
              <div class="rc-image column .rc-maxcon mr-3">
                <img :src="item.image + '?' + new Date().getTime()" style="max-width:128px" @click="showBigImage(index)" />
              </div>
              <div class="rc-image column rc-fitcon">
                <div class="rc-image-actions-wrapper">
                  <VaButton :disabled="medhistory.closed || medhistory.discharged || item.user != userProfile.id" preset="primary" icon="edit" class="mr-2" @click="editImage(item.id)"></VaButton>
                  <VaButton :disabled="medhistory.closed || medhistory.discharged || (!iamOwner && item.user != userProfile.id)" preset="primary" color="danger" icon="delete" @click="deleteImage(item.id)"></VaButton>
                </div>
                <p>Дата снимка: {{ item.date }}</p>
                <p>Загрузил: {{ item.user_full_name }}</p>
                <p class="mb-2">Описание: {{ item.comment ? item.comment : 'отсутствует' }}</p>
              </div>
            </div>
          </div>
          <VaButton :disabled="medhistory.closed || medhistory.discharged || !iamDoctor" preset="primary" icon="add" @click="showImageModal=!showImageModal">Загрузить снимок</VaButton>
        </VaCollapse>
        </VaAlert>

        <VaAlert v-if="comments.length"
          color="info"
          class="mb-4"
          border="left"
        >
          <VaCardTitle class="rc-text-m">
            Комментарии
          </VaCardTitle>
          <div class="mb-3 rc-comments-wrapper">
            <div v-for="comment in comments" class="mb-3">
              <Comments :comment="comment" :isOwner="comment.user === userProfile.id"/>
            </div>
          </div>
          <VaButton
            v-if="(((comments.length && iamOwner) || iamBoss) && !medhistory.accepted) || (medhistory.closed && iamOwner)"
            preset="primary"
            color="primary"
            class="mr-2 mb-2 rc-flex-right"
            icon="comment"
            @click="commentModal=!commentModal"
            id="commentButton"
          >
            Написать комментарий
          </VaButton>
        </VaAlert>


          <VaAlert
            v-if="ambvisits.length > 0"
            color="warning"
            border="left"
            class="mb-4"
          >
            <template #icon>
              <VaIcon name="info" />
            </template>
            Пациент посещал амбулаторию. Вы можете посмотреть записи приемов и прикрепить их к Истории болезни
          <VaCollapse
            header="Посмотреть записи"
          >
            <span class="rcflex row rcflex-gap mb-4 vcenter" v-for="(ambvisit, index) in ambvisits">
              <span class="mb-3">
                {{ index + 1 }}. {{ ambvisit.examType == 0 ? "Первичный" : "Повторный"}} прием у врача {{ ambvisit.doctor_full_name }} от {{ ambvisit.visitDate }}
              </span>
              <span>
                <VaButton
                  preset="primary"
                  class="mr-2 mb-2"
                  @click="showAmbVisit(index,ambvisits)"
                  icon="description"
                >
                  Подробно
                </VaButton>
                <VaButton
                  :disabled="medhistory.closed || !iamDoctor"
                  preset="primary"
                  class="mr-2 mb-2"
                  icon="playlist_add"
                  @click="linkVisit(ambvisit.id)"
                >
                  Прикрепить
                </VaButton>
              </span>
            </span>
          </VaCollapse>
          </VaAlert>

          <VaAlert
            v-if="attachedAmbvisits.length > 0"
            color="info"
            border="left"
            class="mb-4"
          >
          <VaChip square shadow size="large" color="#ADD8E6" style="padding:10px"><VaCardTitle class="rc-text-m">Амбулатория</VaCardTitle></VaChip>

          <VaCardContent v-for="(aav, index) in attachedAmbvisits">
            <span class="rcflex row mb-3 rcflex-gap">
            {{ (aav.examType == 0) ? "Первичный" : "Повторный"}} осмотр: {{ aav.visitDate }}
          </span>
            <span>
              <VaButton
                preset="primary"
                class="mr-2"
                icon="description"
                @click="showAttachedAmbVisit(index,attachedAmbvisits)"
              >
                Подробно
              </VaButton>
              <VaButton
                :disabled="medhistory.closed || !iamDoctor" 
                preset="primary" 
                color="danger" 
                icon="cancel"
                class="mr-2"
                @click="linkVisit(aav.id, false)">
                Открепить
              </VaButton>
            </span>
          </VaCardContent>
          </VaAlert>

          <VaAlert
            color="primary"
            border="left"
            class="mb-4 noflex"
          >
          
          <VaChip square shadow size="large" color="#FFE4B5" style="padding:10px"><VaCardTitle class="rc-text-m">Стационар</VaCardTitle></VaChip>
          <VaAlert color="#ffffff" class="pa-0 mt-3 mb-3" v-if="hosvisit">
            <VaCardContent class="rcflex row vcenter rcflex-gap rcflex-btw">
              <span><b>Первичный осмотр от:</b> {{ hosvisit.visitDate }}</span>
              <VaButton
                  preset="primary"
                  class="rcflex"
                  @click="showHosVisitModal = !showHosVisitModal"
                  icon="description"
                >
                  Подробно
                </VaButton>
            </VaCardContent>
          </VaAlert>
            <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
              <VaButton 
                :disabled="medhistory.closed || !iamDoctor || !iamOwner"
                preset="primary"
                icon="playlist_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'visitNew', params: {patientId: medhistory.patient, examType: 0, depType: 1, regId: 0}})"
                >
                Добавить первичный осмотр
                </VaButton>
            </VaCardContent>

            <div v-if="!medhistory.localAnesthesia"> 
              <VaAlert color="#ffffff" class="pa-0 mb-2" v-if="anexamExists">
                <VaCardContent class='rcflex row vcenter rcflex-gap rcflex-btw'>
                  <span><b>Осмотр анестезиолога от:</b> {{ anexam.examDate }}</span>
                  <VaButton
                    preset="primary"
                    class="rcflex"
                    @click="anexamModal=!anexamModal"
                    icon="description"
                  >
                    Подробно
                  </VaButton>
                </VaCardContent>
              </VaAlert>

              <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
                <VaButton
                  :disabled="medhistory.closed || !iamAnesthesist"
                  preset="primary"
                  icon="ecg"
                  class="rcflex-right mr-2"
                  @click="$router.push({name:'addAnExam', params:{patientId: medhistory.patient, medhistoryId: medhistoryId, visitId: hosvisit ? hosvisit.id : 0, regId: 0 }})"
                  >
                  Добавить осмотр анестезиолога
                </VaButton>
              </VaCardContent>

              <VaCardContent v-if="!anexamExists && ambanexam" class="rcflex row vcenter rcflex-gap rcflex-btw">
                <VaAlert 
                  border="left"
                  color="info"
                  style="width:100%;background-color:#ffffff"
                >
                  <template #icon>
                    <VaIcon name="info" />
                  </template>
                  <p class="mb-2">
                    Есть осмотр анестезиолога-реаниматолога в амбулатории<br/>
                  </p>
                  <p class="mb-2">
                    Дата и время осмотра: {{ ambanexam.examDate }}
                  </p>
                  <p class="mb-2">
                    Анестезиолог-реаниматолог: {{ ambanexam.anesthesist_full_name }}
                  </p>
                  <VaButton
                    :disabled="medhistory.closed || (!iamAnesthesist && !iamOwner)"
                    preset="primary"
                    icon="description"
                    class="rcflex-right mr-2"
                    @click.before="$store.dispatch('app/setAnexam', ambanexam)"
                    @click="anexamModal=!anexamModal"
                    >
                    Посмотреть
                  </VaButton>
                  <VaButton
                    :disabled="medhistory.closed || (!iamAnesthesist && !iamOwner)"
                    preset="primary"
                    icon="playlist_add"
                    class="rcflex-right mr-2"
                    @click="linkAmbAnExam"
                    >
                    Прикрепить этот осмотр анестезиолога
                  </VaButton>
                </VaAlert>
              </VaCardContent>
            </div>

            <VaCardContent>
              <VaMenu>
                <template #anchor>
                  <VaButton :disabled="!hosvisit || medhistory.closed" placement="left" icon="playlist_add" preset="primary">Добавить осмотр специалиста</VaButton>
                </template>

                <VaMenuItem @selected="console.log('Терапевт')" icon="stethoscope" :disabled="!iamDoctor">
                  Терапевт
                </VaMenuItem>
                <VaMenuItem @selected="console.log('Кардиолог')" icon="cardiology" :disabled="!iamDoctor">
                  Кардиолог
                </VaMenuItem>
                <VaMenuItem @selected="console.log('Невролог')" icon="neurology" :disabled="!iamDoctor">
                  Невролог
                </VaMenuItem>
              </VaMenu>
            </VaCardContent>

            <VaAlert color="#ffffff" class="pa-0 mb-3"  v-if="preop.id">
              <VaCardContent class='rcflex row vcenter rcflex-gap rcflex-btw'>
                <span><b>Предоперационный эпикриз от:</b> {{ preop.preopDate }}</span>
                <VaButton
                  preset="primary"
                  class="rcflex"
                  @click="preopModal=!preopModal"
                  icon="description"
                >
                  Подробно
                </VaButton>
              </VaCardContent>
            </VaAlert>
            <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
              <VaButton
                preset="primary"
                :disabled="(!hosvisit || medhistory.closed || (!anexamExists && !medhistory.localAnesthesia)) || !iamDoctor || !iamOwner"
                icon="playlist_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'preop', params: {patientId: medhistory.patient, medhistoryId: medhistoryId, anexamId: anexam.id===null ? 'none' : anexam.id}})"
                >
                Добавить предоперационный эпикриз
                </VaButton>
            </VaCardContent>

            <VaAlert color="#ffffff" class="pa-0 mb-3" v-if="operation.id">
              <VaCardContent class="rcflex row vcenter rcflex-gap rcflex-btw">
                <span><b>Ход операции от:</b> {{ operation.createDate }}</span>
                  <VaButton
                      preset="primary"
                      class="rcflex"
                      @click="showOperationModal = !showOperationModal"
                      icon="description"
                    >
                      Подробно
                  </VaButton>
              </VaCardContent>
            </VaAlert>
            <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
              <VaButton
                preset="primary"
                :disabled="(!preop.id && !medhistory.localAnesthesia) || medhistory.closed || !iamDoctor"
                icon="playlist_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'addOperation', params: { patientId: medhistory.patient, medhistoryId: medhistoryId }})"
                >
                Добавить ход операции
                </VaButton>
            </VaCardContent>

            <VaAlert color="#ffffff" class="pa-0 mb-3" v-if="discharge.id">
              <VaCardContent class="rcflex row vcenter rcflex-gap rcflex-btw">
                  <span><b>Выписной эпикриз от:</b> {{ discharge.created }}</span>
                  <VaButton
                      preset="primary"
                      class="rcflex"
                      @click="showDischargeModal = !showDischargeModal"
                      icon="description"
                    >
                      Подробно
                  </VaButton>
              </VaCardContent>
            </VaAlert>
            <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
              <VaButton
                preset="primary"
                :disabled="(!hosvisit || medhistory.closed) || !iamDoctor || !iamOwner || !medhistory.discharge_date"
                icon="playlist_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'addDischargeEpicrisis'})"
                >
                Добавить выписной эпикриз
                </VaButton>
            </VaCardContent>

            <VaAlert color="#ffffff" class="pa-0 mb-3" v-if="title.id">
              <VaCardContent class="rcflex row vcenter rcflex-gap rcflex-btw">
                  <span><b>Титульный лист:</b> {{ title.created }}</span>
                  <VaButton
                      preset="primary"
                      class="rcflex"
                      @click="titleModal = !titleModal"
                      icon="description"
                    >
                      Подробно
                  </VaButton>
              </VaCardContent>
            </VaAlert>
            <VaCardContent v-else class="rcflex row vcenter rcflex-gap rcflex-btw">
              <VaButton
                preset="primary"
                :disabled="(!discharge.id || medhistory.closed) || !iamDoctor || !iamOwner"
                icon="playlist_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'titleAdd'})"
                >
                Добавить титульный лист
                </VaButton>
            </VaCardContent>

            <VaDivider/>
            
            <VaCardContent>
              <VaCardTitle class="rc-text-m">Дневники</VaCardTitle>
              <VaSplit v-if="diary.length>0"
                class="split-demo split-demo--image pr-1"
                :limits="[['260px', '260px'],['any', 'any']]"
                :vertical="window.width > 576 ? false : true"
              >
                <template #start="{ containerSize }">
                    <VaDatePicker
                      class="mr-3"
                      stateful
                      v-model="diaryDateSelected"
                      :allowedDays="(date) => checkDiaryForDay(date)"
                    />
                </template>
                <template #end="{ containerSize }" class="ml-3">
                    <div v-for="(record, index) in diarySelected" :key="index">
                      <div class="va-table-responsive ml-2">
                        <VaAlert color="#ffffff">
                          <DiaryDetails :diary="record"/>
                          <VaButton :disabled="medhistory.closed || !iamDoctor || (!iamOwner && record.user != userProfile.id)"
                            preset="primary"
                            color="primary"
                            icon="edit"
                            class="mr-2"
                            @click="$router.push('/medhistory/diary/edit/'+record.id)"
                          >
                            Изменить
                          </VaButton>
                          <VaButton
                            preset="primary"
                            color="primary"
                            icon="print"
                            class="mr-2"
                            @click.before="diaryToPrint=[];diaryToPrint.push(diarySelected[index])"
                            @click="print('print','diary',true)"
                          >
                            Печать
                          </VaButton>
                        </VaAlert>
                      </div>
                    </div>
                </template>
              </VaSplit>
              <VaButton
                :disabled="(!hosvisit || medhistory.closed) || !iamDoctor"
                preset="primary"
                icon="assignment_add"
                class="rcflex-right mr-2"
                @click="$router.push({name: 'addDiary', params: { medhistoryId: medhistoryId }})"
                >
                Добавить дневник
              </VaButton>
              <VaButton
                            preset="primary"
                            color="primary"
                            icon="print"
                            class="mr-2"
                            @click.before="diaryToPrint=diary"
                            @click="print('print','diary',true)"
                          >
                            Печать дневников
                          </VaButton>
            </VaCardContent>

            <VaDivider/>

            <VaCardContent>
              <VaCardTitle class="rc-text-m">Назначения</VaCardTitle>
            <VaButton
                :disabled="(!hosvisit || medhistory.closed) || !iamDoctor || !iamOwner || medhistory.discharged"
                preset="primary"
                icon="pill"
                class="rcflex-right mr-2"
                @click="newPrescriptionModal=!newPrescriptionModal"
                >
                Добавить назначение
              </VaButton>

              <VaDataTable
                  :columns="prescriptionsLabels"
                  :items="prescriptions"
                  striped
                  clickable
                  noDataHtml="Назначений пока нет"
                  >
                  <template #cell(name)="{ rowData }">
                    <div class="rcflex row">
                      <div class="rcflex column">
                        <div class="rcflex row"><p>{{ rowData.name }}, {{ rowData.dosage }}</p><VaButton class="ml-2" preset="primary" icon="edit"  @click="editPrescription(rowData.id)"/></div>
                        <p>{{ rowData.method }} - {{ rowData.freq }} рвд</p>
                        <p>{{ rowData.dateStart }} - {{ rowData.dateEnd }}</p>
                        <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
                      </div>
                    </div>
                  </template>
                  <template #cell(take)="{ rowData }">
                    <div class="rcflex row">
                    <div v-for="item,index in rowData.fact">
                      <div class="mr-4 column">
                      <div style="line-height:1.5rem" class="rcflex center">{{ formatDate(new Date(parseInt(index)), 'short') }}</div>
                        <div v-for="f in rowData.fact[index]" class="rcflex center">
                          
                                    <VaDropdown trigger="hover" placement="left-center" :offset="[10, 0]" class="rcflex">

                                      <template #anchor>
                                        <VaButton class="mr-2" :icon="f.datetime ? 'done' : f.missed ? 'close' : 'schedule'" :preset="!f.datetime && !f.missed ? 'plainOpacity' : 'plain'" :color="f.datetime ? 'success' : f.missed ? 'warning' : 'secondary'"/>
                                      </template>

                                      <VaDropdownContent style="background-color:#f8f8f8;border:1px dashed #dddddd">
                                        {{ f.datetime ? 'Исполнено: ' + f.datetime + '\nМедсестра: ' + f.nurse_full_name : f.missed ? 'Просрочено' : 'Запланировано' }}
                                      </VaDropdownContent>

                                    </VaDropdown>
                        </div>
                      </div>
                    </div>
                    </div>
                  </template>
                </VaDataTable>
                <VaButton class="mt-2" preset="primary" icon="print" @click="print('print','prescriptions')" :disabled="!prescriptions.length">Лист назначений</VaButton>
            </VaCardContent>

            <VaDivider/>

            <VaCardContent>
              <VaCardTitle class="rc-text-m">Исследования</VaCardTitle>
              <VaMenu>
                <template #anchor>
                  <VaButton :disabled="(!hosvisit || medhistory.closed) || !iamDoctor || medhistory.discharged" placement="left" icon="labs" preset="primary">Назначить исследование</VaButton>
                </template>

                <VaMenuItem @selected="newTest(1)" icon="hematology">
                  Общий анализ крови
                </VaMenuItem>
                <VaMenuItem @selected="newTest(2)" icon="spo2">
                  Биохимический анализ крови
                </VaMenuItem>
                <VaMenuItem @selected="newTest(3)" icon="urology">
                  Общий анализ мочи
                </VaMenuItem>
                <VaMenuItem @selected="newTest(4)" icon="glucose">
                  Коагулограмма
                </VaMenuItem>
                <VaMenuItem @selected="newTest(5)" icon="radiology">
                  Рентген
                </VaMenuItem>
              </VaMenu>
                <VaDataTable
                  :columns="testsLabels" 
                  :items="tests"
                  striped
                  clickable
                  noDataHtml="Исследования не назначались"
                  >
                  <template #cell(type)="{ rowData }">
                    {{ rowData.planDate }}<br/>
                    {{ testTypes[rowData.type] }}<VaButton class="ml-2" preset="primary" icon="edit" @click="editTest(rowData.id)" />
                    <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
                  </template>
                  <template #cell(taken)="{ rowData, rowIndex }">
                    <div v-if="rowData.taken">
                      <div v-if="new Date(rowData.factDate.split(' ')[0].split('.').reverse().join('-')) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
                        <VaChip flat icon="warning" color="secondary">Взят: {{rowData.factDate}}</VaChip>
                        <p class="rc-text-s">С опозданием</p>
                      </div>
                      <div v-else>
                        <VaChip flat icon="done" color="success">Взят: {{rowData.factDate}}</VaChip>
                      </div>
                      <VaButton v-if="rowData.result===''" preset='primary' @click="showTestResultModal(rowIndex, rowData.type, testTypes[rowData.type])">Внести результат</VaButton>
                      <VaCollapse v-else header="Результат">{{ rowData.result }}</VaCollapse>
                    </div>
                    <div v-else>
                      <div v-if="new Date(today) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
                        <VaChip flat icon="error" color="danger">Дата забора прошла</VaChip>
                      </div>
                      <div v-else>
                        <VaChip flat icon="schedule" color="secondary">Запланирован</VaChip>
                      </div>
                    </div>
                  </template>
                </VaDataTable>
              </VaCardContent>
            </VaAlert>
          
    </VaCardContent>
  </VaCard>
  
  
  <VaModal
    :allowBodyScroll=true
    ref="showImageModal"
    backgroundColor="background-secondary"
    v-if="showImageModal"
    v-model="showImageModal"
    max-height="85vh"
    size="small"
    hideDefaultActions
    fixed-layout
    :title="imageEdit ? 'Изменить снимок' : 'Новый снимок'"
  >
    <VaForm class="mt-2 rcflex column" ref="imageForm">
      <img v-if="imageEdit && imageToEdit.image !== undefined" :src="imageToEdit.image" style="max-width:256px" />
      <VaFileUpload
        color="primary"
        v-model="imageData.image"
        type="single"
        file-types="image/*"
        :uploadButtonText="imageEdit ? 'Заменить снимок' : 'Выбрать снимок'"
      />
      <VaDateInput
        label="Дата снимка"
        v-model="imageData.date"
        class="mb-2"
      />
      <VaTextarea
        class="mb-2"
        v-model="imageData.comment"
        label="Комментарий"
        placeholder=""
        style="width:100%"
        autosize
        :minRows=3
        :max-rows=5
      />
      <div class="rcflex row ">
        <VaButton
          preset="primary"
          color="secondary"
          @click="$refs.showImageModal.hide()" 
          >
          {{ imageEdit ? "Назад" : "Отмена"}}
        </VaButton>
        <VaButton
          :disabled=!imageData.image.name
          v-if="!imageEdit"
          class="ml-2"
          preset="primary"
          @click.before="prepareImage('add')"
          @click="$refs.showImageModal.hide()" 
          >
          Сохранить
        </VaButton>
        <VaButton
          v-if="imageEdit"
          class="ml-2"
          preset="primary"
          color="danger"
          @click.before="deleteImage(imageToEdit.id)"
          @click="$refs.showImageModal.hide()" 
          >
          Удалить
        </VaButton>
        <VaButton
          v-if="imageEdit"
          class="ml-2"
          preset="primary"
          @click.before="prepareImage('patch', imageToEdit.id)"
          @click="$refs.showImageModal.hide()" 
          >
          Сохранить изменения
        </VaButton>
      </div>
    </VaForm>
  </VaModal>
  
  
  
  
  <VaModal
    :allowBodyScroll=true
    ref="newPrescriptionModal"
    backgroundColor="background-secondary"
    v-if="newPrescriptionModal"
    v-model="newPrescriptionModal"
    max-height="85vh"
    size="small"
    hideDefaultActions
    fixed-layout
    title="Назначить препарат"
  >
    <VaForm class="mt-2 rcflex column">
      <VaSelect
        v-model="prescriptionSelected"
        class="col-span-1 mb-2"
        label="Наименование препарата"
        placeholder="Начните писать..."
        noOptionsText="Нет совпадений, Enter - создать новый"
        autocomplete
        highlight-matched-text
        :options="medicamentList"
        :textBy="(option) => option.name"
        :valueBy="(option) => option.name"
        :rules="[(v) => v || 'Field is required']"
        allow-create
        @create-new="showMedicamentModal"
      />
      <VaSelect
        v-model="prescription.dosage"
        class="col-span-1 mb-2"
        label="Дозировка"
        placeholder="Выберите дозировку"
        :options="dosageList"
        :disabled="!prescription.name"
      />
      <VaSelect
        class="mb-2"
        label="Метод введения"
        background="#FFFFFF"
        v-model="prescription.method"
        :options="methodList"
        placeholder="Выбрать способ введения"
        
      />
      <VaDateInput
        label="Когда начать?"
        v-model="prescription.date"
        class="mb-2"
      />
      <label class="va-input-label" style="color: var(--va-primary);">Частота введения</label>
      <div class="mb-2 row vcenter notouch">
        <VaCounter
          class="mr-3"
          style="max-width:140px"
          v-model="prescription.freq"
          :min=1
          :max=8
        />
        <div>раз в день</div>
      </div>
      <label class="va-input-label" style="color: var(--va-primary);">Продолжительность курса</label>
      <div class="mb-2 row vcenter">
        <VaCounter
          class="mr-3"
          style="max-width:240px"
          v-model="prescription.duration"
          :min=1
          :max=14
          />
        <div>{{ dayString(prescription.duration) }}</div>
      </div>
      <VaTextarea
        class="mb-2"
        v-model="prescription.comment"
        label="Комментарий"
        placeholder=""
        style="width:100%"
        autosize
        :minRows=3
        :max-rows=5
      />
      <div class="rcflex row ">
        <VaButton
          preset="primary"
          color="secondary"
          @click="prescriptionEdit=false; $refs.newPrescriptionModal.hide()" 
          >
          {{ prescriptionEdit ? "Назад" : "Отмена"}}
        </VaButton>
        <VaButton
          v-if="!prescriptionEdit"
          class="ml-2"
          preset="primary"
          @click.before="addPrescription"
          @click="$refs.newPrescriptionModal.hide()" 
          >
          Сохранить
        </VaButton>
        <VaButton
          v-if="prescriptionEdit"
          class="ml-2"
          preset="primary"
          color="danger"
          @click.before="deletePrescription"
          @click="$refs.newPrescriptionModal.hide()" 
          >
          Удалить
        </VaButton>
        <VaButton
          v-if="prescriptionEdit"
          class="ml-2"
          preset="primary"
          @click.before="patchPrescription"
          @click="$refs.newPrescriptionModal.hide()" 
          >
          Сохранить
        </VaButton>
      </div>
    </VaForm>
  </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="newTestModal"
            v-model="newTestModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            title="Запланировать исследование"
            ref="newTestModal"
          >
            {{ test.label }}
            <VaForm class="mt-2">
              <VaDateInput
                v-model="test.date"
              />
              <VaTextarea
                class="mb-2"
                v-model="test.comment"
                label="Комментарий"
                placeholder=""
                style="width:100%"
                autosize
                :minRows=3
                :max-rows=5
              />

              <VaButton class="ml-2" @click="$refs.newTestModal.hide()" preset="primary" color="secondary">Отменить</VaButton>
              <VaButton class="ml-2" preset="primary" @click.before="$refs.newTestModal.hide()" @click="addTest(test.type, test.date)">Сохранить</VaButton>
            </VaForm>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="editTestModal"
            v-model="editTestModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            title="Редактирование исследования"
            ref="editTestModal"
          >
            {{ editTestItem.label }}
            <VaForm class="mt-2">
              <VaDateInput
                v-model="editTestItem.date"
              />
              <VaTextarea
                class="mb-2"
                v-model="editTestItem.comment"
                label="Комментарий"
                placeholder=""
                style="width:100%"
                autosize
                :minRows=3
                :max-rows=5
              />
              <VaTextarea
                v-if="editTestItem.taken"
                class="mb-2"
                v-model="editTestItem.result"
                label="Результат исследования"
                placeholder=""
                style="width:100%"
                autosize
                :minRows=10
                :max-rows=20
              />

              <VaButton @click="$refs.editTestModal.hide()" preset="primary" color="secondary">Назад</VaButton>
              <VaButton class="ml-2" preset="primary" color="danger" @click.before="$refs.editTestModal.hide()" @click="deleteTest(editTestItem.id)">Удалить</VaButton>
              <VaButton class="ml-2" preset="primary" @click.before="$refs.editTestModal.hide()" @click="updateTest(editTestItem)">Сохранить</VaButton>
            </VaForm>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="testResultModal"
            v-model="testResultModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            title="Результат исследования"
            ref="testResultModal"
          >
          {{ testResult.planDate }} Вы заказали исследование "{{ testTypes[testResult.type] }}" с комментарием: "{{ testResult.comment }}", исполненный {{ testResult.factDate }}
          <VaForm class="mt-2">
              <VaTextarea
                class="mb-2"
                v-model="testResult.result"
                label="Результат исследования"
                placeholder=""
                style="width:100%"
                autosize
                :minRows=10
                :max-rows=20
              />

              <VaButton class="ml-2" @click="$refs.testResultModal.hide()" preset="primary" color="secondary">Отменить</VaButton>
              <VaButton class="ml-2" preset="primary" @click.before="$refs.testResultModal.hide()" @click="addTestResult(testResult.id)">Сохранить</VaButton>
            </VaForm>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            ref="showAmbVisitModal"
            v-if="showAmbVisitModal"
            v-model="showAmbVisitModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Прием у врача ' + ambvisits[curIndex].doctor_full_name + ' от ' +  ambvisits[curIndex].visitDate"
          >
            <VisitPrintForm :visit="ambvisits[curIndex]" v-if="printForm" id="ambvisit"/>
            <VisitDetails :visit="ambvisits[curIndex]" />

            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.showAmbVisitModal.hide()"
              >
                Закрыть
              </VaButton>
              <VaButton
                :disabled="!printForm"
                preset="primary"
                icon="print"
                class="mr-2"
                @click="print('ambvisit')"
              >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || (!iamOwner && ambvisits[curIndex].user!=userProfile.id)"
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click.before="$refs.showAmbVisitModal.hide()"
                @click="$router.push('/visits/edit/'+ambvisits[curIndex].id+'?from=medhistory')"
              >
                Изменить
              </VaButton>
              <VaButton
                :disabled="medhistory.closed || !iamDoctor || !iamOwner"
                preset="primary"
                icon="playlist_add"
                @click.before="$refs.showAmbVisitModal.hide()"
                @click="linkVisit(ambvisits[curIndex].id)"
              >
                Прикрепить
              </VaButton>
            </template>
          </VaModal>
          <VaModal
            :allowBodyScroll=true
            ref="showAttachedAmbVisitModal"
            v-if="showAttachedAmbVisitModal"
            v-model="showAttachedAmbVisitModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Прием у врача ' + attachedAmbvisits[curIndex].doctor_full_name + ' от ' +  attachedAmbvisits[curIndex].visitDate"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>

            <VisitPrintForm :visit="attachedAmbvisits[curIndex]" v-if="printForm" id="attachedAmbvisit"/>
            <VisitDetails :visit="attachedAmbvisits[curIndex]" v-else/>

            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.showAttachedAmbVisitModal.hide()"
              >
                Закрыть
              </VaButton>
              <VaButton
                :disabled="!printForm"
                preset="primary"
                icon="print"
                class="mr-2"
                @click="print('attachedAmbvisit')"
              >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || (!iamOwner && attachedAmbvisits[curIndex].user!=userProfile.id)"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click.before="$refs.showAttachedAmbVisitModal.hide()"
                @click="$router.push('/visits/edit/'+attachedAmbvisits[curIndex].id+'?from=medhistory')"
              >
                Изменить
              </VaButton>
              <VaButton
                :disabled="medhistory.closed || !iamDoctor || !iamOwner"
                preset="primary"
                color="danger"
                icon="cancel"
                @click.before="$refs.showAttachedAmbVisitModal.hide()"
                @click="linkVisit(attachedAmbvisits[curIndex].id, false)"
              >
                Открепить
              </VaButton>
            </template>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="showHosVisitModal"
            v-model="showHosVisitModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Первичный осмотр у врача ' + hosvisit.doctor_full_name + ' от ' +  hosvisit.visitDate"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>
              
            <VisitPrintForm :visit="hosvisit" v-if="printForm" id ="hosvisit"/>
            <VisitDetails :visit="hosvisit" v-else />

            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="showHosVisitModal = !showHosVisitModal"
              >
                Закрыть
              </VaButton>
              <VaButton
                :disabled="!printForm"
                class="mr-2" 
                icon="print" 
                preset="primary" 
                @click="print('hosvisit')"
                >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || (!iamOwner && hosvisit.user!=userProfile.id)"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click="$router.push('/visits/edit/'+hosvisit.id+'?from=medhistory')"
              >
                Изменить
              </VaButton>
            </template>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="showOperationModal"
            v-model="showOperationModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Ход операции от ' +  operation.createDate"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>
            <OperationReport v-if="printForm && operationReport" id="operationReport" />
            <OperationPrintForm v-if="printForm && !operationReport" id="operation" />
            <OperationDetails :operation="operation" v-if="!printForm && !operationReport"/>
            
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="showOperationModal = !showOperationModal"
              >
                Закрыть
              </VaButton>
              <VaButton 
                :disabled="!printForm"
                class="mr-2" 
                icon="print" 
                preset="primary" 
                @click="print('operation')"
                >
                Печать
              </VaButton>
              <VaButton 
                :disabled="!printForm"
                class="mr-2" 
                icon="print" 
                preset="primary"
                @click.before="operationReport=true"
                @click="print('operationReport', false, true)"
                >
                Отчет
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || (!iamOwner && operation.operator!=userProfile.id && operation.assistOne!=userProfile.id && operation.assistTwo!=userProfile.id)"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click="$router.push({name:'editOperation', params: {operationId: operation.id, medhistoryId: medhistoryId, patientId: patient.id}})"
              >
                Изменить
              </VaButton>
            </template>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="showDischargeModal"
            v-model="showDischargeModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Выписной эпикриз от ' +  discharge.created"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>

            <DischargeEpicrisis v-if="printForm" id="discharge"
              :patient="patient"
              :medhistory="medhistory"
              :discharge="discharge"
              :date="date" />
            <DischargeEpicrisisDetails :discharge="discharge" v-else/>
            
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="showDischargeModal = !showDischargeModal"
              >
                Закрыть
              </VaButton>
              <VaButton 
                :disabled="!printForm"
                class="mr-2" 
                icon="print" 
                preset="primary" 
                @click="print('discharge')"
                >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || (discharge.user!=userProfile.id && !iamOwner)"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click="$router.push({name:'editDischargeEpicrisis', params: {dischargeId: discharge.id}})"
              >
                Изменить
              </VaButton>
            </template>
          </VaModal>

          <VaModal
            :allowBodyScroll=true
            v-if="anexamModal"
            v-model="anexamModal"
            ref="anexamModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Осмотр анестезиолога от ' + anexam.examDate"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>
            <AnExamPrintForm v-if="printForm" id="anexam"/>
            <AnExamDetails :anexam="anexamExists ? anexam : ambanexam" v-else/>
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.anexamModal.hide()"
              >
                Закрыть
              </VaButton>
              <VaButton 
                :disabled="!printForm || !anexamExists"
                class="mr-2" 
                icon="print" 
                preset="primary" 
                @click="print('anexam')"
                >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamAnesthesist || !anexamExists"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click.before="$refs.anexamModal.hide()"
                @click="$router.push('/anexam/edit/'+anexam.id+'?from=medhistory')"
              >
                Изменить
              </VaButton>
              <VaButton v-if="!anexamExists"
                :disabled="!iamAnesthesist && !iamOwner"
                preset="primary"
                color="primary"
                icon="playlist_add"
                class="mr-2"
                @click.before="$refs.anexamModal.hide()"
                @click="linkAmbAnExam"
              >
                Прикрепить
              </VaButton>
            </template>
          </VaModal>

          <VaModal
            :allowBodyScroll=true  
            ref="preopModal"
            v-if="preopModal"
            v-model="preopModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            :title="'Предоперационный эпикриз от ' + preop.preopDate"
          >
            <template #header>
              <VaButtonToggle
                v-model="printForm"
                preset="secondary"
                :options="formOptions"
                class="mb-4"
              />
            </template>
            <PreopPrintForm v-if="printForm" id="preop" />
            <PreopDetails :preop="preop" v-else />
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.preopModal.hide()"
              >
                Закрыть
              </VaButton>
              <VaButton 
                :disabled="!printForm"
                class="mr-2" 
                icon="print" 
                preset="primary" 
                @click="print('preop')"
                >
                Печать
              </VaButton>
              <VaButton :disabled="medhistory.closed || !iamDoctor || !iamOwner"
                preset="primary"
                color="primary"
                icon="edit"
                class="mr-2"
                @click="$router.push('/preop/edit/'+preop.id)"
              >
                Изменить
              </VaButton>
            </template>
          </VaModal>
          
          <VaModal
            :allowBodyScroll=true
            v-if="showQRModal"
            v-model="showQRModal"
            max-height="85vh"
            hideDefaultActions
            fixed-layout
            title="Ссылка на Историю болезни"
            class="center"
          >
              <VueQRCodeComponent :text="baseUrl + $route.fullPath" :size=196 />
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="center"
                @click="showQRModal = !showQRModal"
              >
                Закрыть
              </VaButton>
            </template>
          </VaModal>
          <VaModal
            :allowBodyScroll=true
            ref="commentModal"
            v-if="commentModal"
            v-model="commentModal"
            size="medium"
            hideDefaultActions
            fixed-layout
            title="Новый комментарий"
          >
            <VaForm>
              <VaTextarea
                v-model="newComment"
                :rules="[(v) => v.length > 2 || 'Напишите что-нибудь (минимум 3 символа)']"
                label="Комментарий"
                placeholder=""
                style="width:100%"
                autosize
                :minRows=5
                :max-rows=10
              />
            </VaForm>
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.commentModal.hide()"
              >
                Отмена
              </VaButton>
              <VaButton
                :disabled="newComment.length<3"
                preset="primary"
                color="primary"
                icon="comment"
                class="mr-2"
                @click="postComment"
                @click.after="$refs.commentModal.hide()"
              >
                Отправить
              </VaButton>
            </template>
          </VaModal>
          <VaModal
            :allowBodyScroll=true
            ref="newMedicamentModal"
            v-if="newMedicamentModal"
            v-model="newMedicamentModal"
            size="small"
            hideDefaultActions
            fixed-layout
            title="Новый препарат"
          >
            <VaForm class="rcflex column">
              <VaInput autofocus label="Наименование препарата" v-model="newMedicamentName" />
              <VaSelect label="Ед.изм." v-model="dosageAffixTemp"placeholder="ед.изм." :options="['мл','л','мкг','мг','г','мкг/мл','мг/мл','г/мл',]" />
              <VaInput label="Дозировка" v-model="dosageTemp" @keyup.enter="addDosage()">
                <template #appendInner>
                  Нажмите Enter для добавления
                </template>
              </VaInput>
              <VaCard class="mt-4 mb-2">
                <VaCardContent>
                  <VaAlert color="warning" v-if="dosageAffixError">Нужно выбрать единицу измерения</VaAlert>
                  <div class="mt-4 rcflex column">
                    <span class="mb-2">Добавляем: {{ newMedicamentName }}</span>
                    <span class="mb-2" :key="dosageTempArray">Дозировка: 
                      <VaChip class="mr-2 mb-2" color="info" v-for="(item, index) in dosageTempArray">
                        {{ item }}
                        <VaButton icon="close" preset="plain" color="#ffffff" @click="deleteDosage(index)"/>
                      </VaChip>
                    </span>
                  </div>
                </VaCardContent>
              </VaCard>
            </VaForm>
            <template #footer>
              <VaButton
                preset="primary"
                color="secondary"
                icon="close"
                class="mr-2"
                @click="$refs.newMedicamentModal.hide()"
              >
                Отмена
              </VaButton>
              <VaButton
                :disabled="!newMedicamentValid"
                preset="primary"
                color="primary"
                icon="save"
                class="mr-2"
                @click="$refs.newMedicamentModal.hide()"
                @click.after="addMedicament"
              >
                Сохранить
              </VaButton>
            </template>
          </VaModal>
    <VaModal
      :allowBodyScroll=true
      ref="transferModal"
      v-model="transferModal"
      hideDefaultActions
      fixed-layout
      title="Уточним детали" >
      <p class="mb-2">Кому передать пациента?</p>
      <VaSelect
            class="mb-2"
            v-model="newDoctorId"
            label="Принимающий врач"
            placeholder="Выберите из списка"
            text-by="full_name"
            value-by="id"
            :options="doctors.filter(item => item.id !== userProfile.id && item.is_superuser === false)"
        />
        <div class="rcflex row rcflex-btw">
                <VaButton
                  class="center" 
                  preset="primary"
                  color="secondary"
                  @click.before="$refs.transferModal.hide()" 
                  >
                  Отмена
                </VaButton>
                <VaButton
                  class="center" 
                  preset="primary"
                  :loading="patchingMedhistory"
                  @click="patchMedhistory({ transfer: newDoctorId },'transfer',$refs.transferModal)"
                  >
                  Продолжить
                </VaButton>
              </div>
    </VaModal>
    <VaModal :allowBodyScroll=true v-if="imageWorking" v-model="imageWorking" hideDefaultActions>
      <VaProgressCircle indeterminate style="margin: 0 auto"></VaProgressCircle>
      Работаем со снимками...
    </VaModal>
    <VaModal
      :allowBodyScroll=true
      ref="printModal"
      v-model="printModal"
      hideDefaultActions
      title="Печать документа" >
      <div id="print">
        <DiaryPrintForm :patient="patient" :diary="diaryToPrint" :printMedhistory="false" v-if="document==='diary'" />
        <PharmacyComissionPrintForm v-if="document==='pharmacy' && patient.birthdate" :patient="patient" />
        <DischargeEpicrisis v-if="document==='dischargeEpicrisis'"
              :patient="patient"
              :medhistory="medhistory"
              :discharge="discharge"
              :date="date" />
        <Prescriptions v-if="document==='prescriptions'" />
      </div>
      <template #footer>
        <VaButton
          class="center" 
          preset="primary"
          color="secondary"
          @click="$refs.printModal.hide()" 
          >
          Закрыть
        </VaButton>
      </template>
    </VaModal>

    <VaModal
      :allowBodyScroll=true
      ref="titleModal"
      v-if="titleModal"
      v-model="titleModal"
      max-height="85vh"
      hideDefaultActions
      fixed-layout
      :title="'Титульный лист от ' + title.created"
    >
      <template #header>
        <VaButtonToggle
          v-model="printForm"
          preset="secondary"
          :options="formOptions"
          class="mb-4"
        />
      </template>
      <TitlePrintForm :patient="patient" v-if="printForm" id="title" />
      <TitleDetails v-else />
      <template #footer>
        <VaButton
          preset="primary"
          color="secondary"
          icon="close"
          class="mr-2"
          @click="$refs.titleModal.hide()"
        >
          Закрыть
        </VaButton>
        <VaButton 
          :disabled="!printForm"
          class="mr-2" 
          icon="print" 
          preset="primary" 
          @click="print('title')"
          >
          Печать
        </VaButton>
        <VaButton :disabled="medhistory.closed || !iamDoctor || !iamOwner"
          preset="primary"
          color="primary"
          icon="edit"
          class="mr-2"
          @click="$router.push({name: 'titleEdit'})"
        >
          Изменить
        </VaButton>
      </template>
    </VaModal>
<!-- Печать истории болезни -->
    <VaModal
      :allowBodyScroll=true
      v-if="medhistoryPrintModal"
      v-model="medhistoryPrintModal"
      ref="medhistoryPrintModal"
      max-height="85vh"
      hideDefaultActions
      fixed-layout
      :title="'История болезни пациента ' +  patient.full_name"
    >
      <div id="medhistory">
        <TitlePrintForm :patient="patient" />
        <VisitPrintForm :visit="hosvisit" />
        <PreopPrintForm />
        <AnExamPrintForm v-if="!medhistory.localAnesthesia"/>
        <OperationPrintForm />
        <DiaryPrintForm :patient="patient" :diary="diary" :printMedhistory="true"/>
        <DischargeEpicrisis
          :patient="patient"
          :medhistory="medhistory"
          :discharge="discharge"
          :date="date" />
      </div>
        <template #footer>
          <VaButton
            preset="primary"
            color="secondary"
            icon="close"
            class="mr-2"
            @click="$refs.medhistoryPrintModal.hide()"
          >
            Закрыть
          </VaButton>
          <VaButton 
            class="mr-2" 
            icon="print" 
            preset="primary" 
            @click="print('medhistory')"
            >
            Печать
          </VaButton>
        </template>
    </VaModal>
<!-- /Печать истории болезни -->
    <VaModal
      :allowBodyScroll=true
      ref="showBigImageModal"
      v-model="showBigImageModal"
      hideDefaultActions
      fixed-layout
      :closeButton=true
      size="medium"
      >
      <!-- <div style="width:512px"> -->
        <img style="width:100%" :src="images[imageIndex].image"/>
      <!-- </div> -->
    </VaModal>

</template>
<script setup>
  import { testTypes, testTemplates } from "@/common/locale"
  import { formatDate, formatDateTime, dayString, detectOS } from '@/common/rcfx.js'
</script>
<script>
import axiosInstance from "../services/api"
import resizeImage from '@/common/image-resize';
import EventBus from "../common/EventBus"
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile'
import DiaryDetails from '@/components/DiaryDetails'
import PreopDetails from '@/components/PreopDetails'
import VisitDetails from '@/components/VisitDetails'
import OperationDetails from '@/components/OperationDetails'
import DischargeEpicrisisDetails from '@/components/DischargeEpicrisisDetails'
import VueQRCodeComponent from 'vue-qrcode-component-v3'
import _ from 'lodash'
import debounce from 'lodash.debounce'
import AnExamDetails from "@/components/AnExamDetails"
import Comments from "@/components/Comments"
import DischargeEpicrisis from '@/components/printforms/DischargeEpicrisis'
import VisitPrintForm from "@/components/printforms/VisitPrintForm.vue"
import PharmacyComissionPrintForm from "@/components/printforms/PharmacyComissionPrintForm"
import AnExamPrintForm from "@/components/printforms/AnExamPrintForm"
import PreopPrintForm from "@/components/printforms/PreopPrintForm.vue"
import OperationPrintForm from "@/components/printforms/OperationPrintForm.vue"
import OperationReport from "@/components/printforms/OperationReport"
import Prescriptions from "@/components/printforms/Prescriptions"
import DiaryPrintForm from "@/components/printforms/DiaryPrintForm"
import TitlePrintForm from "@/components/printforms/TitlePrintForm"
import TitleDetails from "@/components/TitleDetails"


Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
}

export default {
    components: { RouterLink, PatientProfile, DiaryDetails, PreopDetails, PreopPrintForm, VisitDetails, VisitPrintForm, OperationDetails, OperationPrintForm, OperationReport, Prescriptions, DischargeEpicrisisDetails, DiaryPrintForm, Comments, AnExamDetails, AnExamPrintForm, VueQRCodeComponent, TitleDetails, TitlePrintForm },
    props: {
      medhistoryId: String,
    },
    data() {
        return {
            date: formatDate(new Date(), "ru"),
            formOptions: [
              { label: "Табличная форма", value: false },
              { label: "Печатная форма", value: true },
            ],
            printWindow: false,
            action:'',
            document: '',
            delta: {
              room: '',
              paid: '',
              localAnesthesia: '',
            },
            baseUrl: window.location.origin,
            methodList: ['Перорально', 'Внутривенно','Внутримышечно','Внутрисуставно','Подкожно','Сублингвально'],
            dosageList: [],
            prescriptionSelected: '',
            medicamentList: [],
            prescriptionsLabels: [
              { key: "name", label: "Препарат, частота, курс", width: "40%" },
              { key: "take", label: "Журнал приема", width: "60%" },
            ],
            testsLabels: [
              { key: "type", label: "Дата и тип исследования", width: 25 },
              { key: "taken", label: "Статус", width: 24 },
            ],
            operationReport: false,
            transferModal: false,
            newPrescriptionModal: false,
            newTestModal: false,
            editTestModal: false,
            showQRModal: false,
            preopModal: false,
            testResultModal: false,
            showAmbVisitModal: false,
            showAttachedAmbVisitModal: false,
            showHosVisitModal: false,
            showOperationModal: false,
            titleModal: false,
            anexamModal: false,
            commentModal: false,
            showDischargeModal: false,
            newMedicamentModal: false,
            medhistoryPrintModal: false,
            printModal: false,
            printForm: true,
            toggleCard:false,
            newMedicamentName: '',
            dosageTempArray: [],
            dosageTemp: '',
            dosageAffixTemp: '',
            dosageAffixError: false,
            newMedicamentId: '',
            bloodTypes: [
                "",
                "0(I)",
                "A(II)",
                "B(III)",
                "AB(IV)",
              ],
            medhistory: {},
            patient: {},
            hosvisit: {},
            attachedAmbvisits: [],
            ambvisits: [],
            preop: {},
            operation: {},
            discharge:{},
            diary: [],
            diarySelected: [],
            diaryDates:[],
            diaryDateSelected: null,
            diaryToPrint:[],
            curIndex: Number,
            test: {
              date: new Date,
              planDate: '',
              label: '',
              type: 0,
              taken: false,
              patient: '',
              medhistory: this.medhistoryId,
            },
            tests: [],
            testsLoading: true,
            testResult: {},
            editTestId: undefined,
            editTestItem: {},
            prescriptionsLoading: true,
            logsLoading: true,
            logsJoined: false,
            prescriptions: [],
            prescription: {
              name: '',
              dosage: '',
              method: '',
              freq: 3,
              duration: 3,
              date: new Date,
              dateStart: '',
              dateEnd: '',
              patient: '',
              medhistory: this.medhistoryId,
              comment: '',
            },
            prescriptionEdit: false,
            logs:[],
            today: formatDate(new Date, 'us'),
            window: { width: 0, height: 0},
            status: true,
            anexam: {},
            anexamExists: false,
            ambanexam: undefined,
            comments: {},
            commentsExists: false,
            newComment: '',
            bosses: [],
            newDoctorId: '',
            patchingMedhistory: false,
            title: {id: null},
            images: [],
            imageData: {
              user: null,
              image: [],
              comment: '',
              date: new Date,
              medhistory: '',
            },
            imageIndex: null,
            imageEdit: false,
            imageToEdit: {},
            imageWorking: false,
            showImageModal: false,
            showBigImageModal: false,
          }
    },
    computed: {
      newMedicamentValid() {
        if (this.newMedicamentName) return this.newMedicamentName.length>0 && this.dosageTempArray.length>0
      },
      userProfile() {
        return this.$store.state.app.account.profile 
      },
      iamBoss: function() {
        return this.userProfile.boss ? true : false
      },
      iamDoctor: function() {
          return this.userProfile.iamDoctor
      },
      iamRegistrator: function() {
        return this.userProfile.iamRegistrator
      },
      iamNurse: function() {
        return this.userProfile.iamNurse
      },
      iamAnesthesist: function() {
        return this.userProfile.iamAnesthesist
      },
      iamOwner: function() {
        return this.$store.state.app.account.profile.id === this.medhistory.user
      },
      prescriptionsReady: function() {
        return  !this.prescriptionsLoading && !this.logsLoading ? true : false
      },
      readyToPrint: function() {
        return this.title.id && this.hosvisit.id && this.preop.id && (this.anexam.id || this.medhistory.localAnesthesia) && this.operation.id && this.discharge.id
      }
    },
    watch: {
      dosageAffixTemp: function(value) {
        if (value.length>0) this.dosageAffixError = false
      },
      prescriptionsReady: function(newValue) {
        if (newValue == true) {
         this.joinLogs()
        }
      },
      prescriptionSelected: function(newValue){
        if (newValue!='') {
          this.prescription.name = newValue
          this.dosageList = this.medicamentList.filter((val) => val.name == newValue)[0].dosage
          this.prescription.dosage = this.dosageList[0]
        }
      },
      diaryDateSelected: function(newvalue) {
        this.diaryForDate(newvalue)
      },
      imageWorking: function(newValue) {
        if (newValue==false) {
          this.imageEdit = false
          this.imageData = { image: [], comment: '', date: new Date }
        }
      },
      showImageModal: function(newValue) {
        if (newValue==false && this.imageWorking == false) {
          this.imageEdit = false
          this.imageData = { image: [], comment: '', date: new Date }
        }
      }
    },
    methods: {
      showBigImage(index) {
        this.imageIndex = index
        this.showBigImageModal = true
      },
      showTestResultModal(testId, testType, testName) {
        this.testResult = this.tests[testId]
        this.testResult.result = testName + ' от ' + this.tests[testId].factDate.split(' ')[0] + ': ' + testTemplates[testType] + ': '
        this.testResultModal = true
      },
      async print(elid, document = false, wait = false) {
        if (document !== false) {
          if (!this.patient.birthdate) {
            this.$vaToast.init({ message: 'У пациента не указана дата рождения.', color: 'warning', position: 'top-right'})
            return
          }
          this.document = document
          this.printModal = true
          await this.printModal && this.$htmlToPaper(elid, null, ()=> {
            // this.printModal = false 
            this.$refs.printModal.hide()
          })
        }else{
          //console.log('else')
          if (wait) {
            await this.operationReport && this.$htmlToPaper(elid, null, ()=> {this.operationReport=false})  
          }else{
            await this.$htmlToPaper(elid)
          }
        }
      },
      openMedhistory() {
        this.patchMedhistory({closed: !this.medhistory.closed}, true)
      },
      async confirmDischarge() {
        var discharge_date = formatDateTime(new Date, "us")
        this.$vaModal.confirm({
          size: "medium",
          message: 'Пациент будет выписан датой: '+ formatDateTime(new Date, "ru") +'\n\nЧто дальше?\n\n1. Будет зафиксирована дата выписки, указанная выше.\n2. Станет доступно создание выписного эпикриза\n',
          title: 'Выписка пациента',
          okText: "Да выписываем",
          cancelText: "Отмена",
        })
        .then((ok) => {if (ok) {this.patchMedhistory({discharged: true, discharge_date: discharge_date}, "discharge")}})
      },
      async confirmAcceptMedhistory() {
        var accepted_date = formatDateTime(new Date, "us")
        this.$vaModal.confirm({
          size: "medium",
          message: 'Истории болезни будет принята датой: '+ formatDateTime(new Date, "ru") +'\n\nЧто дальше?\n\n1. История болезни уйдет архив и ее можно будет просматривать\n2. История болезни станет недоступна для изменения никому из персонала\n3. Вновь открыть историю болезни будет невозможно',
          title: 'Принятие истории болезни',
          okText: "Да принимаю",
          cancelText: "Отмена",
        })
        .then((ok) => {if (ok) {this.patchMedhistory({accepted: true, accepted_date: accepted_date}, "accept")}})
      },
      async confirmCloseMedhistory() {
        var closeDate = formatDateTime(new Date, "us")
        this.$vaModal.confirm({
          size: "medium",
          message: 'История болезни будет закрыта датой: '+ formatDateTime(new Date, "ru") +'\n\nЧто дальше?\n\n1. Историю болезни можно будет просматривать, но она станет недоступна для изменения\n2. Вложенные документы также будут заблокированы \n3. Медсестры не смогут исполнять назначения и брать анализы по этой истории болезни\n4. История болезни уйдет на проверку Главному врачу\n5. Вновь открыть историю болезни для редактирования сможет только Главный врач',
          title: 'Закрытие истории болезни',
          okText: "Да закрываем",
          cancelText: "Отмена",
        })
        .then((ok) => {if (ok) {this.patchMedhistory({closed: true, closeDate: closeDate }, "close")}})
      },
      stopListenEsc() {
        let apptalk = new BroadcastChannel('inapp')
        apptalk.postMessage({ code: "listenEsc", state: false })
      },
      listenEsc(hide) {
        let apptalk = new BroadcastChannel('inapp')
        apptalk.postMessage({ code: "listenEsc", state: true })
        hide && hide()
      },
      toggle(modal, state) {

        //console.log('Отправляю событие:' + this.$refs.length)
        let apptalk = new BroadcastChannel('inapp')
        //apptalk.postMessage({ code: "listenEsc", state: !state })
        switch (modal) {
          case "AmbVisitModal":
            this.showAmbVisitModal = state
            break
          case "AttachedAmbVisitModal":
            this.showAttachedAmbVisitModal = state
            break
          case "HosVisitModal":
            this.showHosVisitModal = state
            break
          case "preopModal":
            this.preopModal = !this.preopModal
            break
          case "PrescriptionModal":
            this.newPrescriptionModal = state
            break
          case "TestModal":
            this.newTestModal = state
            break
          case "QRModal":
            this.showQRModal = state
            break
          case "OperationModal":
            this.showOperationModal = state
            break

          default:
            break
        }
      },
      handleResize: debounce(function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            }, 100),
      // handleResize() {
      //       this.window.width = window.innerWidth;
      //       this.window.height = window.innerHeight;
      // },
      makeRows(row) {
        var t = this.makeGraph(row.dateStart, row.duration)
        var result = '<tr class="va-data-table__table-tr">'
        for (let c=0;c < t.length; c++) {
          var date = t[c].split('.')
          result += '<td class="va-data-table__table-td" style="text-align:center">' + date[0] + '.' + date[1] + '</td>'
        }
        result += '</tr>'
        for (let i=0;i<row.freq;i++) {
          result += '<tr style="text-align:center">'
            for (let a=0;a<row.duration;a++) {
              result += '<td class="va-data-table__table-td compact" style="text-align:center">*'
              result += '</td>'
            }
            result += '</tr>'
        }
        return result
      },
      joinLogs(){
        for (let i=0;i<this.prescriptions.length;i++) {
          this.prescriptions[i].logs=[]
          for (let e=0;e<this.logs.length;e++) {
            if (this.logs[e].prescription == this.prescriptions[i].id) {
                this.prescriptions[i].logs.push(this.logs[e])
              }
          }
        }
        this.convertLogs(this.prescriptions)
      },
      convertLogs(prescriptions) {
        var today = new Date(formatDate(new Date, 'ru').split('.').reverse().join('-')).getTime()
          if (prescriptions.length > 0) {
            for (var e = 0; e < prescriptions.length; e++) {
              var dates = this.makeGraph(prescriptions[e].dateStart, prescriptions[e].duration)
              //console.log('asdasdasd '+dates)
              prescriptions[e].fact = {}
                for (var d = 0; d < dates.length; d++) {
                  var currentDate = new Date(dates[d].split('.').reverse().join('-')).getTime()
                  prescriptions[e].fact[currentDate] = []
                  var count = 0
                  for (var f = 0; f < prescriptions[e].logs.length; f++) {
                    var foundDate = new Date(prescriptions[e].logs[f].datetime.split(' ')[0].split('.').reverse().join('-')).getTime()
                    if (currentDate === foundDate) {
                      prescriptions[e].fact[currentDate].push(prescriptions[e].logs[f])
                      count++
                    }
                  }
                  if (count<prescriptions[e].freq) {
                    var delta = parseInt(prescriptions[e].freq) - count
                    for (var k=0;k<delta;k++){
                      var missed = currentDate < today ? true : false
                      prescriptions[e].fact[currentDate].push({missed: missed})
                    }
                  }
                }
              }
          }
      },
      makeGraph(start, duration) {
        var result = []
        for (let i=0; i<duration; i++) {
          var a = start.split('.').reverse().join('-')
          var b = new Date(a).addDays(i)
          result.push(formatDate(b,'ru'))
        }
//        return result.toString()
        return result
      },
      getLogs() {
        var date = this.medhistory.openDate.split(' ')[0].split('.').reverse().join('-')
        axiosInstance
            //.get('/medhistory/prescriptionlogs?medhistory=' + this.medhistoryId + '&from=' + date)
            .get('/medhistory/prescriptionlogs?medhistory=' + this.medhistoryId)
            .then(response => {
              this.logs = response.data.results
            })
            .catch(error => {
              //console.log('Logs get error: ' + error);
            })
            .finally(() => {
              this.logsLoading = false
            })
      },
      getImages() {
        this.imageWorking = true
        axiosInstance
            .get('/images?medhistory=' + this.medhistoryId)
            .then(response => {
              this.images = response.data.results
            })
            .catch(error => {
              console.log('Images get error: ' + error);
            })
            .finally(() => {
              this.imageWorking = false
            })
      },
      editImage(id) {
        this.imageToEdit = this.images.find((item)=>item.id == id)
        this.imageData.user = this.userProfile.id
        this.imageData.date = new Date(this.imageToEdit.date.split('.').reverse().join('-'))
        this.imageData.comment = this.imageToEdit.comment
        this.imageData.medhistory = this.imageToEdit.medhistory
        this.imageEdit = true
        this.showImageModal = true

      },
      prepareImage(action, id = null) {
        this.imageWorking = true
        if (this.imageData.image.name) {
          this.$vaToast.init({ message: 'Конвертация снимка началась', color: 'success', position: 'top-right'})
          const platform = detectOS()
          const filename = this.imageData.image.name
          resizeImage({ file: this.imageData.image, maxSize: 1200, os: platform })
          .then((blobImage) => {
            this.$vaToast.init({ message: 'Конвертация снимка прошла успешно', color: 'success', position: 'top-right'})
            let file = new File([blobImage], filename)
            this.imageData.image = file
            action == "add" && this.addImage()            
            action == "patch" && this.patchImage(id)            
          })
          .catch((error)=> {
            console.log('Resize error')
            this.$vaToast.init({ message: 'Не удалось конвертировать снимок', color: 'warning', position: 'top-right'})
          })
        }else{
          this.$vaToast.init({ message: 'Конвертация пропущена..', color: 'secondary', position: 'top-right'})
          action == "add" && this.addImage()            
          action == "patch" && this.patchImage(id)            
        }
      },
      addImage() {
        this.imageData.date = this.imageData.date.toLocaleDateString().split('.').reverse().join('-')
        this.imageData.medhistory = this.medhistoryId
        this.imageData.user = this.userProfile.id
        axiosInstance
            .post('/images', this.imageData, { headers: {'Content-Type': 'multipart/form-data'} })
            .then(response => {
              this.images.push(response.data)
              this.$vaToast.init({ message: 'Снимок загружен', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Image not loaded: ' + error);
              this.$vaToast.init({ message: 'Снимок не загружен', color: 'warning', position: 'bottom-right'})
            })
            .finally(() => {
              this.imageWorking = false
              this.imageData = { image: [], comment: '', date: new Date }
            })
      },
      patchImage(id) {
        try {
          this.imageData.date = this.imageData.date.split('.').reverse().join('-')
        }catch{
          this.imageData.date = this.imageData.date.toLocaleDateString().split('.').reverse().join('-')
        }
        axiosInstance
            .patch('/images/' + this.imageToEdit.id, this.imageData, { headers: {'Content-Type': 'multipart/form-data'} })
            .then(response => {
              let result = response.data
              let index = this.images.findIndex((item) => item.id == result.id)
              this.images.splice(index, 1, result)
              this.$vaToast.init({ message: 'Снимок изменен', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Image not loaded: ' + error);
              this.$vaToast.init({ message: 'Снимок не изменен', color: 'warning', position: 'bottom-right'})
            })
            .finally(() => {
              this.imageWorking = false
              this.imageToEdit = {}
            })
      },
      deleteImage(id) {
        this.imageWorking = true
        axiosInstance
            .delete('/images/' + id)
            .then(response => {
              this.images = this.images.filter((item) => item.id !== id)
              this.$vaToast.init({ message: 'Снимок удален', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Image not deleted: ' + error);
              this.$vaToast.init({ message: 'Снимок не удалось удалить', color: 'warning', position: 'bottom-right'})
            })
            .finally(() => {
              this.imageWorking = false
              this.imageToEdit = {}
            })

      },
      getPrescriptions() {
        this.prescriptionsLoading = true
        axiosInstance
            .get('/medhistory/prescriptions?all&patient=' + this.medhistory.patient + '&medhistory=' + this.medhistoryId)
            .then(response => {
              this.prescriptions = response.data.results
              this.prescriptionsLoading = false
              this.$store.dispatch('app/setPrescriptions', this.prescriptions)
            })
            .catch(error => {
              //console.log('Test get error: ' + error);
            })
      },
      addPrescription() {
        this.prescription.patient = this.patient.id
        this.prescription.dateStart = formatDate(this.prescription.date, 'us')
        this.prescription.dateEnd = formatDate(this.prescription.date.addDays(this.prescription.duration-1), 'us')
        //console.log(this.prescription.dateEnd)
        axiosInstance
            .post(`/medhistory/prescription/new`, this.prescription)
            .then(response => {
              //console.log(JSON.stringify(response.data))
              this.getPrescriptions()
              this.$vaToast.init({ message: 'Назначение добавлено', color: 'success', position: 'bottom-right'})
              this.prescriptionSelected = ''
              this.prescription.name= ''
              this.prescription.dosage= ''
              this.prescription.method= ''
              this.prescription.freq= 3
              this.prescription.duration= 3
              this.prescription.dateStart= ''
              this.prescription.dateEnd= ''
              this.prescription.comment= ''
            })
            .catch(error => {
              //console.log('Prescription post error: ' + error);
            })
      },
      patchPrescription() {
        this.prescription.dateStart = formatDate(this.prescription.date, 'us')
        this.prescription.dateEnd = formatDate(this.prescription.date.addDays(this.prescription.duration-1), 'us')
        axiosInstance
            .patch(`/medhistory/prescription/`+this.prescription.id, this.prescription)
            .then(response => {
              //console.log(JSON.stringify(response.data))
              this.getPrescriptions()
              this.$vaToast.init({ message: 'Назначение изменено', color: 'success', position: 'bottom-right'})
              this.prescriptionSelected = ''
              this.prescription.name= ''
              this.prescription.dosage= ''
              this.prescription.method= ''
              this.prescription.freq= 3
              this.prescription.duration= 3
              this.prescription.dateStart= ''
              this.prescription.dateEnd= ''
              this.prescription.comment= ''
              this.prescriptionEdit = false
            })
            .catch(error => {
              //console.log('Prescription patch error: ' + error);
            })
      },
      deletePrescription() {
        axiosInstance
            .delete(`/medhistory/prescription/`+this.prescription.id)
            .then(response => {
              this.getPrescriptions()
              this.$vaToast.init({ message: 'Назначение удалено', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              //console.log('Prescription delete error: ' + error);
            })
      },
      addTestResult(testId) {
        axiosInstance
            .patch('/medhistory/test/' + testId, { taken: true, result: this.testResult.result })
            .then(response => {
              // this.tests[index] = response.data.results
              this.getTests()
            })
            .catch(error => {
              //console.log('Test get error: ' + error);
            })
      },
      updateTest(payload) {
        var planDate = formatDate(payload.date, 'us')
        axiosInstance
            .patch('/medhistory/test/' + payload.id, { planDate: planDate, comment: payload.comment, result: payload.result, taken: payload.taken })
            .then(response => {
              // this.tests[index] = response.data.results
              this.$vaToast.init({ message: 'Исследование изменено', color: 'success', position: 'bottom-right'})
              this.getTests()
            })
            .catch(error => {
              //console.log('Test get error: ' + error);
            })
      },
      deleteTest(testId, payload) {
        axiosInstance
            .delete('/medhistory/test/' + testId)
            .then(response => {
              this.$vaToast.init({ message: 'Исследование удалено', color: 'success', position: 'bottom-right'})
              this.getTests()
            })
            .catch(error => {
              //console.log('Delete get error: ' + error);
            })
      },
      getTests() {
        this.testsLoading = true
        axiosInstance
            .get('/medhistory/tests?patient=' + this.medhistory.patient + '&medhistory=' + this.medhistoryId)
            .then(response => {
              this.tests = response.data.results
              this.$store.dispatch('app/setTests', this.tests)
            })
            .catch(error => {
              //console.log('Test get error: ' + error);
            })
            .finally(() => {
              this.testsLoading = false
            })
      },
      addTest() {
        this.test.planDate = formatDate(this.test.date, 'us')
        axiosInstance
            .post(`/medhistory/test/new`, this.test)
            .then(response => {
              //console.log(response.data)
              this.getTests()
              this.$vaToast.init({ message: 'Исследование добавлено в график забора', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              //console.log('Test post error: ' + error);
            })
      },
      newTest(type) {
        this.test.label = testTypes[type]
        this.test.type = type
        this.newTestModal = true
      },
      editTest(id) {
        this.editTestItem = this.tests.filter((item)=>item.id == id)[0]
        this.editTestItem.label = testTypes[this.editTestItem.type]
        this.editTestItem.date = new Date(this.editTestItem.planDate.split('.').reverse().join('-'))
        this.editTestModal = true
      },
      editPrescription(id) {
        this.prescriptionEdit = true
        this.prescription = this.prescriptions.filter((item)=>item.id == id)[0]
        this.prescription.date = new Date(this.prescription.dateStart.split('.').reverse().join('-'))
        this.prescriptionSelected = this.prescription.name
        this.newPrescriptionModal = true
      },
      //Принимает: объект Date, Возвращает: JS Timestamp этой даты в 00:00:00
      dateToTimeStamp(d) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime()
      },
      //Принимает: строку Python date, Возвращает: JS объект Date той же даты в 00:00:00
      dateParse(d) {
        var date = d.split(' ')[0].split('.')
        return new Date(date[2], date[1] - 1, date[0])
      },
      //Принимает: массив записей дневника, Возвращает: ничего, Добавляет: столбец ts -> JS Timestamp
      makeDiaryTimeStamps(array) {
        for (let i = 0; i < array.length; i++) {
          var d = array[i].diaryDate.split(' ')[0].split('.')
          var ts = new Date(d[2], d[1] - 1, d[0]).getTime()
          this.diary[i].diaryTS = ts
          this.diaryDates.push(ts)
        }
      },
      //Принимает: Объект Date, Возвращает: true|false, Производит поиск даты в массиве записей дневника
      checkDiaryForDay(d) {
        return this.diaryDates.includes(this.dateToTimeStamp(d))
      },
      //Принимает: Объект Date, Возвращает: ничего, Заполняет: массив дневников на выбранную дату
      diaryForDate(d) {
        this.diarySelected = this.diary.filter((record) => record.diaryTS == this.dateToTimeStamp(d))
      },
      linkVisit(visitId, link=true) {
        if (link == true) {
          var mh = this.medhistoryId
          var msg = 'Амбулаторный осмотр прикреплен к Истории болезни'
          var clr = 'success'
        }else{
          var mh = null
          var msg = 'Амбулаторный осмотр откреплен от этой Истории болезни'
          var clr = 'danger'
        }
        axiosInstance
            .patch('/visit/edit/'+visitId, { medhistory: mh })
            .then(response => {
              const message = 'Amb exam attached: ' + JSON.stringify(response)
              this.$vaToast.init({ message: msg, color: clr, position: 'bottom-right'})
              //console.log(message);
              this.getVisits(null, 0, this.medhistory.patient)
              this.getVisits(this.medhistoryId, 0, this.medhistory.patient)
        })
            .catch(error => {
            //console.log('Axios Request Failed: ' + JSON.stringify(error))
            this.addError = true;
        })
            .finally(() => {
        });
      },
      showAmbVisit(value,obj) {
          this.curIndex = value
          this.showAmbVisitModal = true
        },
      showAttachedAmbVisit(value,obj) {
        this.curIndex = value
        this.showAttachedAmbVisitModal = true
      },
      getVisits(medhistory, deptype, patient) {
        axiosInstance
            .get('/visits/?medhistory=' + medhistory + '&deptype=' + deptype + '&patient=' + patient + '&latest=false')
            .then(response => {
                if (deptype == 0) {
                  if (medhistory==null) {
                    //console.log('Обновляем визиты в амбулаторию')
                    this.ambvisits = response.data.results
                  }else{
                    //console.log('Обновляем ПРИКРЕПЛЕННЫЕ визиты в амбулаторию')
                    this.attachedAmbvisits = response.data.results
                  }
                }
                if (deptype == 1) {
                  this.hosvisit = response.data.results[0]
                  this.$store.dispatch('app/setHosvisit', this.hosvisit)
                }
            })
            .catch(error => {
              //console.log('Visits: error ' + error);
        })
            .finally(() => {

        });
      },
      getPreop() {
        axiosInstance
            .get(`/preops/search?patient=`+this.medhistory.patient+'&medhistory='+this.medhistoryId)
            .then(response => {
              this.preop = response.data.results[0]
              this.$store.dispatch('app/setPreop', this.preop)
            })
            .catch(error => {
              //console.log('Preop check: error ' + error);
            })
      },
      getBosses() {
        axiosInstance
            .get('/staff/?group=boss')
            .then(response => {
              const result = response.data.results
              // console.log(JSON.stringify(result))
              for (var prop in result) {
                // let boss = { id: result[prop].id, full_name: result[prop].full_name }
                // console.log('------------Boss: ' + result[prop].full_name)
                this.bosses.push(result[prop].id)
              }
              // this.bosses = result
              //this.sortStaff(response.data.results)
            })
            .catch(error => {
              //console.log('get staff err')
            })
      },
      postComment() {
        var user_to = this.iamOwner ? this.bosses : [this.medhistory.user]
        axiosInstance
        .post('/medhistory/comments/'+this.medhistoryId, { medhistory: this.medhistoryId, comment: this.newComment, user: this.userProfile.id, to: user_to })
        .then(() => {
          this.newComment = ''
          this.$vaToast.init({ message: 'Комментарий добавлен', color: 'success', position: 'bottom-right'})
          this.getComments()
          var recipient = this.iamOwner ? this.bosses : this.medhistory.user
          var payload = {
            title: "У вас новый комментарий",
            body: this.$store.state.app.account.profile.full_name + " добавил комментарий в историю болезни пациента " + this.patient.full_name,
          }
          // this.addEvent("comment", payload, user_to)
        })
        .catch(error => {
          //console.log('Comment post error: ' + error);
        })
      },
      getComments() {
        axiosInstance
            .get('/medhistory/comments/'+this.medhistoryId)
            .then(response => {
              this.comments = response.data.results
            })
            .catch(error => {
              //console.log('Comments get error ' + error);
            })
      },
      patchMedhistory(data, action=false, ref=null) {
        this.patchingMedhistory = true
        axiosInstance
            .patch(`/medhistory/edit/` + this.medhistoryId, data ? data : this.delta)
            .then(response => {
              //console.log(JSON.stringify(response.data))
              this.medhistory = response.data
              this.$store.dispatch('app/setMedhistory', this.medhistory)
              !action && this.$vaToast.init({ message: 'Изменения сохранены', color: 'success', position: 'bottom-right'})
              action === "close" && this.$vaToast.init({ message: 'История болезни ' + (this.medhistory.closed ? 'закрыта' : 'открыта'), color: close ? 'warning' : 'success', position: 'bottom-right'})
              action === "accept" && this.$vaToast.init({ message: 'История болезни принята и помещена в архив', color: 'success', position: 'bottom-right'})
              action === "discharge" && this.$vaToast.init({ message: 'Пациент выписан', color: 'success', position: 'bottom-right'})
              action === "transfer" && this.$vaToast.init({ message: "Запрошена передача пациента врачу: "+response.data.doctor_full_name, color: "success", position: 'bottom-right'})
              action === "acceptTransfer" && this.$vaToast.init({ message: "Вы только что приняли пациента", color: "success", position: 'bottom-right'})
              ref !== null && ref.hide()
              this.patchingMedhistory = false
            })
            .catch(error => {
              this.$vaToast.init({ message: 'Ошибка изменений', color: 'warning', position: 'bottom-right'})
              //console.log('patch: error ' + error);
              this.patchingMedhistory = false
        })
      },
      getMedhistory() {
        axiosInstance
            .get(`/medhistory/` + this.medhistoryId)
            .then(response => {
                this.medhistory = response.data.results[0]
                this.$store.dispatch('app/setMedhistory', this.medhistory)
                // Усли не Врач и не Анестезиолог отправляем в отказ
                if (!this.iamDoctor && !this.iamAnesthesist) this.$router.replace({name:'denied', props:{code: 'e600'}})
                this.delta.room = this.medhistory.room
                this.delta.paid = this.medhistory.paid
                this.delta.localAnesthesia = this.medhistory.localAnesthesia
            })
            .catch(error => {
              //console.log('Templates: error ' + error);
        })
            .finally(() => {
              //console.log('Templates: Request completed')
              this.getAnExam()
              this.getPatient(this.medhistory.patient)
              this.getVisits(null, 0, this.medhistory.patient)
              this.getVisits(this.medhistoryId, 0, this.medhistory.patient)
              this.getVisits(this.medhistoryId, 1, this.medhistory.patient)
              this.getPreop()
              this.getOperation()
              this.getDiary(this.medhistoryId)
              this.getImages()
              this.getPrescriptions()
              this.getLogs()
              this.getTests()
              this.getComments()
              this.getDischargeEpicrisis()
              this.getTitle()
        });
      },
      getAnExam() {
            axiosInstance
            .get(`/medhistory/anexam/search/`+this.medhistoryId)
            .then(response => {
              if (response.data.count > 0) {
                this.anexam = response.data.results[0];
                this.$store.dispatch('app/setAnexam', this.anexam)
                this.anexamExists = true
                }else{
                  this.getAnExamsForPatient()
                }
                
              })
            .catch(error => {
              //console.log('Get anexam error')
            })
          },
      linkAmbAnExam() {
        axiosInstance
        .patch('/medhistory/anexam/' + this.ambanexam.id, { medhistory: this.medhistory.id })
        .then(response => {
          this.anexam = response.data
          this.$store.dispatch('app/setAnexam', this.anexam)
          this.anexamExists = true
          this.$vaToast.init({ message: 'Осмотр анестезиолога прикреплен к этой истории болезни', color: 'success', position: 'bottom-right' })
        })
          .catch(error => {
            this.$vaToast.init({ message: 'Не удалось прикрепить осмотр, попробуйте позже', color: 'warning', position: 'bottom-right' })
            //console.log('Patch anexam Failed: ' + JSON.stringify(error))
        })
      },
      getAnExamsForPatient() {
        axiosInstance
        .get(`/anexams/for/`+this.medhistory.patient)
        .then(response => {
          var r = response
          if (r.data.count > 0) {
            this.ambanexam = r.data.results[0]
          }else{
            this.anexam.id = null
          }
          // this.$store.dispatch('app/setAnexam', this.anexam)
          // this.anexamExists = true
        })
        .catch(error => {
          //console.log('Get amb anexam error')
        })
      },
      getOperation() {
        axiosInstance
            .get(`/medhistory/operation/`+this.medhistoryId)
            .then(response => {
              this.operation = response.data.results[0];
              this.$store.dispatch('app/setOperation', this.operation)
        })
            .catch(error => {
              //console.log('Get operation error')
        })
      },
      getDiary(id) {
            axiosInstance
                .get(`/medhistory/diary/`+id)
                .then(response => {
                  var res = response.data
                  //console.log('--------------' + res.count)
                  if (res.count > 0) {
                    //console.log('-------------- IF')
                    this.diary = res.results.reverse();
                    this.$store.dispatch('app/setDiary', this.diary)
                    this.makeDiaryTimeStamps(this.diary)
                    var latestDiaryDate = new Date(this.diary[0].diaryDate.split(' ')[0].split('.').reverse().join('-'))
                    this.diaryDateSelected = latestDiaryDate
                    //console.log('-------------- IF' + this.diaryDateSelected)
                  }else{
                    //console.log('-------------- ELSE')
                    this.diaryDateSelected = this.dateParse(this.medhistory.openDate)
                  }

            })
                .catch(error => {
                  //console.log('Get diary error')
            })
          },
      getPatient(id) {
        axiosInstance
            .get(`patients/`+id+`/`)
            .then(response => {
              this.patient = response.data;
              this.test.patient = this.patient.id
              this.$store.dispatch('app/setPatient', this.patient)
        })
            .catch(error => {
            this.getPatientError = true;
        })
      },
      addEvent(type,payload,user) {
        //console.log('Trying to add a Event')
        var event = {
          sent:0,
          event: type,
          payload: JSON.stringify(payload),
          recipient: user,
        }
        //console.log(JSON.stringify(event))

        axiosInstance
            .post('/events/new', event)
            .then(response => {
              const message = 'Evend sent ok: ' + JSON.stringify(response)
              //console.log(message);
        })
            .catch(error => {
            //console.log('Event sent failed: ' + JSON.stringify(error))
        })
      },
      showMedicamentModal(newOption) {
        this.newMedicamentName = newOption
        this.newMedicamentModal = true
      },
      newMedicament(newOption) {
        const newItem = {name: newOption, dosage: ["100","200"]}
        this.medicamentList = [...this.medicamentList, newItem];
      },
      deleteDosage(index) {
        this.dosageTempArray.splice(index, 1);
      },
      addDosage() {
        if (!this.dosageTemp.length>0) return
        if (this.dosageAffixTemp.length>0) {
          let item = this.dosageTemp + ' ' + this.dosageAffixTemp
          //console.log('adding dosage: ' + item)

          this.dosageTempArray = [...this.dosageTempArray, item]
          this.dosageTemp = ''
        }else{
          this.dosageAffixError = true
        }
      },
      addMedicament() {
        const newItem = {name: this.newMedicamentName, dosage: JSON.stringify(this.dosageTempArray)}
        axiosInstance
            .post('/medicaments/', newItem)
            .then(response => {
              var data = response.data
              data.dosage =  JSON.parse(data.dosage)
              this.medicamentList = [...this.medicamentList, data]
              this.prescriptionSelected = data.name
              this.newMedicamentName = ''
              this.dosageAffixTemp = ''
              this.dosageTempArray = []
              this.dosageTemp = ''
        })
            .catch(error => {
              //console.log('Medicament add error: ' + error)
            })
      },
      getMedicaments() {
        axiosInstance
            .get('/medicaments/')
            .then(response => {
              this.medicamentList = response.data.results;
              for (let item in this.medicamentList) {
                this.medicamentList[item].dosage = JSON.parse(this.medicamentList[item].dosage)
              }
        })
            .catch(error => {
              //console.log('Medicaments get error')
            })
      },
      getDischargeEpicrisis() {
        axiosInstance
        .get('/discharge/?medhistory='+this.medhistoryId)
        .then((response) => {
          if (response.data.count > 0) {
            this.discharge = response.data.results[0]
            this.$store.dispatch('app/setDE', this.discharge)
          }

        })
        .catch((error) => {
          //console.log('Get discharge epicrisis Failed: ' + JSON.stringify(error))
        })
      },
      getDoctors() {
        axiosInstance
          .get('/staff/?group=doctors')
          .then(response => {
            this.doctors = response.data.results
          })
          .catch(error => {
            //console.log('get staff err')
          })
        },
      acceptTransfer() {
        this.patchMedhistory({ user: this.userProfile.id, transfer:null }, "acceptTransfer")
      },
      transfer() {
        axiosInstance
        .patch('/medhistory/edit/'+this.medhistoryId, { transfer: this.newDoctorId })
        .then((response)=> {
          //console.log('Transfer succesful')
          this.getMedhistory(true)
          this.$vaToast.init({ message: "Запрошена передача пациента врачу: "+response.data.doctor_full_name, color: "success", position: 'bottom-right'})
        })
        .catch((error) => {
          //console.log('Transfer error: ' + JSON.stringify(error))
          this.$vaToast.init({ message: "Ошибка передачи, попробуйте позже", color: "warning", position: 'bottom-right'})
        })
      },
      getTitle() {
        axiosInstance
          .get('/title?medhistory=' + this.medhistoryId)
          .then(response => {
            var res = response.data
            this.title = res.count > 0 ? res.results[0] : {id:undefined}
            this.$store.dispatch('app/setTitle', this.title)
          })
          .catch(error => {
            //console.log('get title err')
          })
      },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      this.$store.dispatch('app/resetAll')
      this.getDoctors()
      this.getBosses()
      this.getMedicaments()
      this.getMedhistory()
      this.medicamentList = _.sortBy(this.medicamentList,['name'])
    },
    beforeUnmount() {
      // this.$store.dispatch('app/resetAll')
    }
}

</script>
<style>
</style>
  