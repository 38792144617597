<template v-if="!preopLoading">
  <VaCard stripe stripe-color="danger" v-if="!preopExists && !preopLoading && !operationId && !this.$store.state.app.medhistory.localAnesthesia">
    <VaCardTitle>Ошибка</VaCardTitle>
    <VaCardContent>
      <VaAlert
        icon="warning"
        color="warning"
        class="rcflex"
      >
      <template #title>
        <span class="rc-text-l rc-text-bold">Сначала нужно создать Предоперационный эпикриз.</span>
      </template>
      </VaAlert>
    </VaCardContent>
    <VaCardContent>
      <VaButton
            class="mr-3"
            icon="cancel"
            preset="primary"
            color="secondary"
            @click="$router.back()"
            >
            Назад
          </VaButton>
    </VaCardContent>
  </VaCard>

  <VaCard stripe stripe-color="success" v-else>
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Ход операции
    </VaCardTitle>
    
    <VaCardContent>
      <VaAlert
        color="primary"
        border="left"
        class="mb-6"
        >
        Ход операции будет прикреплен к истории болезни №{{ medhistoryId }}
      </VaAlert>
      
      <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
          <div class="va-table-responsive">
            <PatientProfile :patient="patient" v-if="patient.passport" />
          </div>
          </VaCollapse>

          <VaForm ref="operationForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
            <VaRadio 
              class="mb-3"
              v-model="form.paid"
              :rules="[(v) => v || 'Отметьте признак оплаты']"
              :options="[
                {
                  text: 'ОМС',
                  value: false,
                },
                {
                  text: 'Платный',
                  value: true,
                },
              ]"
              value-by="value"
            />
          <label class="va-input-label" style="color: var(--va-primary);">Начало операции</label>
          <div class="rcflex row mb-3">
            <VaInput
              :mask="{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '.', onValueChanged: function(e) {rendeDate(e,'startDate')}}"
              class="mr-3"
              v-model="dirty.startDate" 
              style="max-width:150px" 
              placeholder="ДД.ММ.ГГГГ"
            />
            <VaInput
              :mask="{time: true, timePattern: ['h', 'm'], delimiter: ':', onValueChanged: function(e) {rendeDate(e,'startTime')}}"
              class="mr-3"
              v-model="dirty.startTime" 
              style="max-width:100px" 
              placeholder="ЧЧ:ММ"
            />          
          </div>

          <label class="va-input-label" style="color: var(--va-primary);">Конец операции</label>
          <div class="rcflex row mb-3">
            <VaInput
              :mask="{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '.', onValueChanged: function(e) {rendeDate(e,'endDate')}}"
              class="mr-3"
              v-model="dirty.endDate" 
              style="max-width:150px" 
              placeholder="ДД.ММ.ГГГГ"
            />
            <VaInput
              :mask="{time: true, timePattern: ['h', 'm'], delimiter: ':', onValueChanged: function(e) {rendeDate(e,'endTime')}}"
              class="mr-3"
              v-model="dirty.endTime" 
              style="max-width:100px" 
              placeholder="ЧЧ:ММ"
            />          
          </div>

          <VaInput class="mb-3"
            type="text"
            v-model="form.diagnosis"
            label="Предоперационный диагноз"
            placeholder=""
          />

          <VaInput class="mb-3"
            v-model="form.operationNo"
            :rules="[(value) => (value && value.length > 0) || 'Нужно заполнить это поле']"
            label="Номер операции"
          />

          <VaInput class="mb-3"
            v-model="form.operationName"
            :rules="[(value) => (value && value.length > 0) || 'Нужно заполнить это поле']"
            label="Название операции"
          />

          <VaInput class="mb-3"
            v-model="form.operationCode"
            :rules="[(value) => (value && value.length > 0) || 'Нужно заполнить это поле']"
            label="Код операции"
          />

          <VaInput class="mb-3"
            v-model="form.operationCodeKSG"
            :rules="[(value) => (value && value.length > 0) || 'Нужно заполнить это поле']"
            label="Код КСГ"
          />

          <VaInput class="mb-3"
            v-model="form.anesthesia"
            label="Анестезиологическое пособие"
           />

          <VaTextarea
            class="mb-1"
            v-model="form.operationLog"
            label="Ход операции"
            placeholder=""
            autosize
            :minRows=30
            :max-rows=35
          />

          <VaTextarea
            class="mb-3"
            v-model="form.complications"
            label="Осложнения"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=20
          />

          <VaTextarea
            class="mb-1"
            v-model="form.inserted"
            label="Расходный материал"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea
            class="mb-1"
            v-model="form.removed"
            label="Удаленный макропрепарат"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaInput
            class="mb-3"
            type="text"
            v-model="form.diagnosis_postop"
            label="Диагноз постоперационный"
            placeholder="Диагноз постоперационный"
          />

          <VaInput
            class="mb-3"
            type="text"
            v-model="form.mkb10"
            label="Код диагноза"
          />

          <VaTextarea
            class="mb-3"
            v-model="form.recom"
            label="Рекомендации врача-оператора по дальнейшей тактике ведения"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=20
          />
   
          <VaSelect v-if="staffReady"
            class="mb-3"
            v-model="form.operator"
            label="Оператор"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.doctors"
          />

          <VaSelect
            v-if="assistant>0 && staffReady"
            class="mb-3"
            v-model="form.assistOne"
            label="Ассистент №1"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.doctors"
          />
          <VaSelect
            v-if="assistant>1 && staffReady"
            class="mb-3"
            v-model="form.assistTwo"
            label="Ассистент №2"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.doctors"
          />
          <div class="rcflex row">
            <VaButton class="mr-3 mb-3 rcflex" icon="person_remove" v-if="assistant>0 || (form.assistTwo || form.assistTwo)" preset="primary" @click="assistant--">
              Убрать ассистента
            </VaButton>
            <VaButton class="mb-3 rcflex" icon="person_add" v-if="assistant<2" preset="primary" @click="assistant++">
              Добавить ассистента
            </VaButton>
          </div>
          <VaSelect v-if="staffReady"
            class="mb-3"
            v-model="form.opSister"
            label="Операционная медсестра"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.opSisters"
          />
          <VaSelect v-if="staffReady && !medhistory.localAnesthesia"
            class="mb-3"
            v-model="form.anesthetist"
            label="Анестезиолог"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.anesthesists"
          />
          <VaSelect v-if="staffReady && !medhistory.localAnesthesia"
            class="mb-3"
            v-model="form.anSister"
            label="Анестезиологическая медсестра"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.anSisters"
          />
          <VaSelect v-if="staffReady && !medhistory.localAnesthesia"
            class="mb-3"
            v-model="form.xraytech"
            label="Рентген-лаборант"
            placeholder="Выбрать из списка"
            :text-by="(option) => option.full_name"
            :value-by="(option) => option.id"
            :options="staff.xraytechs"
          />
                    
          <div class="rcflex mt-4">
          <VaButton
            preset="primary"
            color="secondary"
            icon="close"
            class="mr-2"
            @click="$router.back()"
          >
            Отмена
          </VaButton>
          <VaButton
            v-if="operationId"
            style="max-width:230px"
            class="" 
            @click="patchOperation()"
            preset="primary"
            icon-right="save"
          >
            Сохранить изменения 
          </VaButton>
          <VaButton
            v-else
            style="max-width:200px"
            class="" 
            @click="addOperation()"
            preset="primary"
            icon-right="system_update_alt"
          >
            Добавить 
          </VaButton>
          </div>          
        </VaForm>
        </VaCardContent>
  </VaCard>

</template>
<script>
import axiosInstance from "../services/api";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';
import { formatDate, formatTime } from '@/common/rcfx.js'
import { useForm } from 'vuestic-ui'

export default {
    components: { RouterLink, PatientProfile },
    props: ['operationId','patientId','medhistoryId'],
    data() {
        return {
            validate: useForm('operationForm').validate, 
            isValid: useForm('operationForm').isValid,
            preopLoading: true,
            assistant:0,
            allStaff: {},
            staff: {
              doctors: [],
              nurses: [],
              anSisters: [],
              opSisters: [],
              anesthesists: [],
              xraytechs: [],
            },
            dirty: { 
              startDate: formatDate(new Date, "ru"),
              startTime: '',
              endDate: formatDate(new Date, "ru"),
              endTime: '',
            },
            startDate: formatDate(new Date, "ru"),
            startTime: '',
            endDate: formatDate(new Date, "ru"),
            endTime: '',
            toggleCard: false,
            form: {
              paid: undefined,
              operationNo: '',
              operationName: '',
              operationCode: '',
              operationCodeKSG: '',
              operationStart: '',
              operationEnd: '',
              patient: this.patientId,
              diagnosis: '',
              anesthesia: '',
              operationLog: '',
              complications: '',
              inserted: '',
              removed: '',
              diagnosis_postop: '',
              mkb10: '',
              recom: '',
              medhistory: this.medhistoryId,
              operator: '',
              assistOne: '',
              assistTwo: '',
              opSister: '',
              anesthetist: '',
              anSister: '',
              xraytech: '',
            },
            patient: {},
            patientLoading: true,
            scheduleLoading:false,
            scheduleUpdated:0,
            preopExists: false,
            preop: {},
            staffReady: false,
          };
    },
    watch: {
      assistant: function(newValue) {
        switch(newValue) {
          case 0:
            this.form.assistOne = ''
            this.form.assistTwo = ''
          
          case 1:
            this.form.assistTwo = ''
          
        }
      }
    },
    computed: {
      medhistory() {
        return this.$store.state.app.medhistory
      },
      hosvisit() {
        return this.$store.state.app.hosvisit
      },
      preop() {
        return this.$store.state.app.preop
      },
    },
    methods: {
      rendeDate(e, field) {
          if (field === "startDate") {
            this.startDate = e.target.value
          }
          if (field === "startTime") {
            this.startTime = e.target.value
          }
          if (field === "endDate") {
            this.endDate = e.target.value
          }
          if (field === "endTime") {
            this.endTime = e.target.value
          }
      },
      checkPreop() {
        axiosInstance
        .get(`/preops/search?patient=`+this.patientId+'&medhistory='+this.medhistoryId)
        .then(response => {
          //console.log('Preop: ' + JSON.stringify(response.data))
          this.preop = response.data.results[0]
          console.log(this.preop.diagnosis)
        })
        .catch(error => {
          console.log('Preop check: error ' + error);
        })
        .finally(() =>{
          this.preopLoading = false
          this.preopExists = this.preop.id ? true : false
          this.form.diagnosis = !this.medhistory.localAnesthesia ? this.preop.diagnosis : this.hosvisit.diagnosis
          this.form.anesthesia = !this.medhistory.localAnesthesia ? this.preop.anesthesia : 'Местная анестезия'
          this.form.diagnosis_postop = !this.medhistory.localAnesthesia ? this.preop.diagnosis : this.hosvisit.diagnosis
          this.form.mkb10 = this.hosvisit.mkb10

        })
      },
      fillTheForm() {
        this.form.diagnosis = this.preop.diagnosis
        this.form.anesthesia = this.preop.anesthesia
        this.form.diagnosis_postop = this.preop.diagnosis
        this.form.mkb10 = this.hosvisit.mkb10
      },
      getStaff() {
        axiosInstance
          .get('/staff/')
          .then(response => {
            this.allStaff = response.data.results
            this.sortStaff(this.allStaff)
          })
          .catch(error => {
            this.gotError = true;
          })
          .finally(() => {
            this.staffReady = true
          })
      },
      getGroup(group) {
        axiosInstance
            .get('/staff/?group=' + group)
            .then(response => {
              this.allStaff = response.data.results
              
        })
            .catch(error => {
            console.log('Doctors Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              this.isLoading = false
        });
      },
      getPatient(id) {
        this.patientLoading = true
        console.log('id is ' + id)
        axiosInstance
            .get(`patients/`+id+`/`)
            .then(response => {
              this.patient = response.data;
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
              this.patientLoading = false
        });
      },
      makeJsDateTime(dbDateTime, object=false) {
        let date = dbDateTime.split(' ')[0]
        let time = dbDateTime.split(' ')[1]
        let jsDateTime = date.split('.')[1]+'.'+date.split('.')[0]+'.'+date.split('.')[2]+' '+time

        return object ? new Date(jsDateTime) : jsDateTime
      },
      makeDbDateTime(str) {
        let date = str.split(' ')[0].reverse().join('-')
        let time = str.split(' ')[1]
        let dbDateTime = date+' '+time

        return dbDateTime
      },
      getOperation() {
        axiosInstance
          .get(`/medhistory/operation/edit/`+this.operationId)
          .then(response => {
            console.log(response.data);
            this.form = response.data
            this.assistant = this.form.assistTwo ? 2 : this.form.assistOne ? 1 : 0
            this.getPatient(this.form.patient)
            this.dirty.startDate = this.form.operationStart.split(' ')[0]
            this.dirty.startTime = this.form.operationStart.split(' ')[1].split(':').slice(0,-1).join(':')
            this.dirty.endDate = this.form.operationEnd.split(' ')[0]
            this.dirty.endTime = this.form.operationEnd.split(' ')[1].split(':').slice(0,-1).join(':')
            this.form.createDate = this.makeJsDateTime(this.form.createDate, true)
            this.preopLoading = false
            console.log(this.form.createDate)
        })
          .catch(error => {
          console.log('Operation get error: ' + error);
        })
      },
      patchOperation() {
        this.form.operationStart = this.startDate.split('.').reverse().join('-') + ' ' + this.startTime
        this.form.operationEnd = this.endDate.split('.').reverse().join('-') + ' ' + this.endTime
        this.form.createTime = formatDate(this.form.createDate, 'us') + ' ' + formatTime(this.form.createDate)
        axiosInstance
          .patch(`/medhistory/operation/edit/`+this.operationId, this.form)
          .then(response => {
            console.log(response.data);
            this.$vaToast.init({ message: 'Ход операции изменен', color: 'success', position: 'bottom-right' })
            this.$router.replace('/medhistory/' + response.data.medhistory)
        })
          .catch(error => {
            this.$vaToast.init({ message: 'Ошибка изменения', color: 'warning', position: 'bottom-right' })
            console.log('Operatin get error: ' + error);
            // this.addError = true;
        })
      },
      addOperation() {
        this.form.operationStart = this.startDate.split('.').reverse().join('-') + ' ' + this.startTime
        this.form.operationEnd = this.endDate.split('.').reverse().join('-') + ' ' + this.endTime
        axiosInstance
          .post(`/medhistory/operation/new`, this.form)
          .then(response => {
            console.log(response.data);
            this.$vaToast.init({ message: 'Ход операции сохранен', color: 'success', position: 'bottom-right' })
            this.$router.replace({name: 'editMedhistory', props: {medhistory: this.medhistoryId}})
        })
          .catch(error => {
            this.$vaToast.init({ message: 'Ошибка заполнения', color: 'warning', position: 'bottom-right' })
            console.log('Operation add error: ' + error);
            // this.addError = true;
        })
      },
      sortStaff(staff) {
        this.staff.doctors = staff.filter(o=>o.groups.find(a=>a.name == 'Врачи'))
        this.staff.anSisters = staff.filter(o=>o.groups.find(a=>a.name == 'Анестезиологические медсестры'))
        this.staff.opSisters = staff.filter(o=>o.groups.find(a=>a.name == 'Операционные медсестры'))
        this.staff.anesthesists = staff.filter(o=>o.groups.find(a=>a.name == 'Анестезиологи'))
        this.staff.xraytechs = staff.filter(o=>o.groups.find(a=>a.name == 'Рентген-лаборанты'))
        this.staff.nurses = staff.filter(o=>o.groups.find(a=>a.name == 'Медсестры'))
      }
    },
    mounted() {
      if (this.operationId) {
        this.getOperation()
      }else{
        console.log(this.medhistory)
        this.form.paid = this.medhistory.paid
        this.getPatient(this.patientId)
        this.checkPreop()
      }
      if (!this.$store.state.app.hosvisit.id || (!this.$store.state.app.preop.id && !this.$store.state.app.medhistory.localAnesthesia)) this.$router.back()
      this.getStaff()
    },
}

</script>
<style>
</style>
  