<template>
  <VaCard stripe stripe-color="success">
    <VaCardTitle>
      Отчеты
    </VaCardTitle>
    <VaTabs
        v-model="reportType"
        grow
        :disabled="loading"
      >
        <template #tabs>
          <VaTab>
            Амбулаторные приемы
          </VaTab>
          <VaTab>
            Выписки
          </VaTab>
          <VaTab>
            Стационарный журнал
          </VaTab>
        </template>
      </VaTabs>
      <Transition :name="slideDirection">
        <VaCardContent v-if="reportType==0">
          <VaCardTitle>Амбулаторные приемы</VaCardTitle>
          <div style="display: flex; flex-direction: column; max-width:600px">
            <label class="va-input-label" style="color: var(--va-primary);">Временной интервал (по дате приема)</label>
            <div class="rc-flex row vcenter">
              <VaDateInput v-model="filter.visits.dateMin" class="mr-2"/>
              <VaIcon name="arrow_right_alt" color="info" class="mr-2"/>
              <VaDateInput v-model="filter.visits.dateMax" />
            </div>
            <label class="va-input-label mb-3" style="color: var(--va-primary);">Возраст</label>
            <VaSlider
            class="mt-2"
              v-model="filter.visits.age"
              range
              track-label-visible
              :min=1
              :max=110
            >
              <template #trackLabel="{ value, order }">
                <VaChip
                  size="small"
                  :color="order === 0 ? 'success' : 'info'"
                >
                  {{ value }} {{ wordforcount(value,['год', 'года', 'лет']) }}
                </VaChip>
              </template>
            </VaSlider>
            <label class="va-input-label" style="color: var(--va-primary);">Пол</label>
            <VaRadio
              v-model="filter.visits.sex"
              :options=sexoptions
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Сельский житель</label>
            <VaRadio
              v-model="filter.visits.villager"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Платный прием</label>
            <VaRadio
              v-model="filter.visits.paid"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <VaButton :loading="loading" class="mt-2 mb-4" preset="primary" @click="getReportVisits(true)">Сформировать</VaButton>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-if="et[reportType]>0"
              >
              Запрос выполнен за {{et[reportType]}} {{wordforcount(reportLength.visits,['секунду', 'секунды', 'секунд'])}}. Всего в выборке: {{ reportLength.visits }} {{ wordforcount(reportLength.visits,['запись', 'записи', 'записей']) }}
            </span>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-else-if="loading===true"
              >
                Запрос выполняется...
          </span>
            </div>
            <VaDataTable
              v-if="reportVisits.length>0"
              :items="reportVisits"
              :columns="columns.visits"
              sticky-header
              hoverable
              :loading="loading"
            >
              <template #cell(visitDate)="{ rowData }">{{ rowData.visitDate.split(" ")[0] }}</template>
              <template #cell(diagnosis)="{ rowData }">
                <VaPopover placement="right" v-if="rowData.diagnosis.length>12" style="max-width:50vw">
                  {{ rowData.diagnosis.slice(0,10) }}...
                  <template #body>{{rowData.diagnosis}}</template>
                </VaPopover>
                <span v-else>{{ rowData.diagnosis }}</span>
              </template>
              <template #cell(mkb10)="{ rowData }">
                
                <VaPopover placement="right" v-if="rowData.mkb10.length>12">
                  {{ rowData.mkb10.slice(0,12) }}...
                  <template #body>{{ rowData.mkb10 }}</template>
                </VaPopover>
                <span v-else>{{ rowData.mkb10 }}</span>
              </template>
              <template #cell(patient_sex)="{ rowData }">{{ sex_str[rowData.patient_sex] }}</template>
              <template #cell(patient_villager)="{ rowData }"> {{ rowData.patient_villager === "True" ? 'Да' : "Нет" }} </template>
              <template #cell(paid)="{ rowData }"> {{ rowData.paid === true ? 'Платный' : "Нет" }} </template>
            </VaDataTable>
            <VaPagination
              v-if="reportLength.visits>0 && pages.visits > 1"
              v-model="filter.visits.page"
              :pages="pages.visits"
              :visible-pages="3"
              buttons-preset="secondary"
              class="justify-center sm:justify-start"
            />
        </VaCardContent>

        <VaCardContent v-else-if="reportType==1">
          <VaCardTitle>Выписки</VaCardTitle>
          <div style="display: flex; flex-direction: column; max-width:600px">
            <label class="va-input-label" style="color: var(--va-primary);">Временной интервал (по дате выписки)</label>
            <div class="rc-flex row vcenter">
              <VaDateInput v-model="filter.discharge.dateMin" class="mr-2"/>
              <VaIcon name="arrow_right_alt" color="info" class="mr-2"/>
              <VaDateInput v-model="filter.discharge.dateMax" />
            </div>
            <label class="va-input-label mb-3" style="color: var(--va-primary);">Возраст</label>
            <VaSlider
            class="mt-2"
              v-model="filter.discharge.age"
              range
              track-label-visible
              :min=1
              :max=110
            >
              <template #trackLabel="{ value, order }">
                <VaChip
                  size="small"
                  :color="order === 0 ? 'success' : 'info'"
                >
                  {{ value }} {{ wordforcount(value,['год', 'года', 'лет']) }}
                </VaChip>
              </template>
            </VaSlider>
            <label class="va-input-label" style="color: var(--va-primary);">Пол</label>
            <VaRadio
              v-model="filter.discharge.sex"
              :options=sexoptions
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Сельский житель</label>
            <VaRadio
              v-model="filter.discharge.villager"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Платный прием</label>
            <VaRadio
              v-model="filter.discharge.paid"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <VaButton :loading="loading" class="mt-2 mb-4" preset="primary" @click="getReportDischarge(true)">Сформировать</VaButton>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-if="et[reportType]>0"
              >
              Запрос выполнен за {{et[reportType]}} {{wordforcount(reportLength.discharge,['секунду', 'секунды', 'секунд'])}}. Всего в выборке: {{ reportLength.discharge }} {{ wordforcount(reportLength.discharge,['запись', 'записи', 'записей']) }}
            </span>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-else-if="loading===true"
              >
                Запрос выполняется...
          </span>
            </div>
            <VaDataTable
              v-if="reportDischarge.length>0"
              :items="reportDischarge"
              :columns="columns.discharge"
              sticky-header
              hoverable
              :loading="loading"
            >
              <template #cell(discharge_date)="{ rowData }">{{ new Date(rowData.discharge_date.split(" ")[0]).toLocaleDateString() }}</template>
              <template #cell(diagnosis)="{ rowData }">
                <VaPopover placement="right" v-if="rowData.diagnosis.length>12" style="max-width:50vw">
                  {{ rowData.diagnosis.slice(0,10) }}...
                  <template #body>{{rowData.diagnosis}}</template>
                </VaPopover>
                <span v-else>{{ rowData.diagnosis }}</span>
              </template>
              <template #cell(patient_sex)="{ rowData }">{{ sex_str[rowData.patient_sex] }}</template>
              <template #cell(patient_villager)="{ rowData }"> {{ rowData.patient_villager === "True" ? 'Да' : "Нет" }} </template>
              <template #cell(paid)="{ rowData }"> {{ rowData.paid == "True" ? 'Платный' : "Нет" }} </template>
            </VaDataTable>
            <VaPagination
              v-if="reportLength.discharge>0 && pages.discharge > 1"
              v-model="filter.discharge.page"
              :pages="pages.discharge"
              :visible-pages="3"
              buttons-preset="secondary"
              class="justify-center sm:justify-start"
            />
        </VaCardContent>
        <VaCardContent v-else-if="reportType==2">
          <VaCardTitle>Стационарный журнал</VaCardTitle>
          <div style="display: flex; flex-direction: column; max-width:600px">
            <label class="va-input-label" style="color: var(--va-primary);">Временной интервал (по дате выписки)</label>
            <div class="rc-flex row vcenter">
              <VaDateInput v-model="filter.journal.dateMin" class="mr-2"/>
              <VaIcon name="arrow_right_alt" color="info" class="mr-2"/>
              <VaDateInput v-model="filter.journal.dateMax" />
            </div>

            <VaSelect
                :disabled="filter.journal.ksg.length>0"
                style="width:100%"
                v-model="filter.journal.code"
                class="col-span-1 mb-2"
                label="Код операции"
                placeholder="Найти код..."
                noOptionsText="Нет совпадений"
                autocomplete
                clearable
                highlight-matched-text
                :options="codes"
                :textBy="(option) => option.operationCode"
                :valueBy="(option) => option.operationCode"
              />
              <label class="va-input-label" style="color: var(--va-primary);">Или</label>
              <VaSelect
                :disabled="filter.journal.code.length>0"
                style="width:100%"
                v-model="filter.journal.ksg"
                class="col-span-1 mb-2"
                label="Код КСГ"
                placeholder="Найти код КСГ..."
                noOptionsText="Нет совпадений"
                autocomplete
                clearable
                highlight-matched-text
                :options="codes"
                :textBy="(option) => option.operationCodeKSG"
                :valueBy="(option) => option.operationCodeKSG"
              />


            <label class="va-input-label mb-3" style="color: var(--va-primary);">Возраст</label>
            <VaSlider
              class="mt-2"
              v-model="filter.journal.age"
              range
              track-label-visible
              :min=1
              :max=110
            >
              <template #trackLabel="{ value, order }">
                <VaChip
                  size="small"
                  :color="order === 0 ? 'success' : 'info'"
                >
                  {{ value }} {{ wordforcount(value,['год', 'года', 'лет']) }}
                </VaChip>
              </template>
            </VaSlider>
            <label class="va-input-label" style="color: var(--va-primary);">Пол</label>
            <VaRadio
              v-model="filter.journal.sex"
              :options=sexoptions
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Сельский житель</label>
            <VaRadio
              v-model="filter.journal.villager"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
            />
            <label class="va-input-label" style="color: var(--va-primary);">Платный прием</label>
            <VaRadio
              v-model="filter.journal.paid"
              :options=options
              :textBy="(option) => option.text"
              :valueBy="(option) => option.value"
              class="mb-2"
            />
            <VaButton :loading="loading" class="mt-2 mb-4" preset="primary" @click="getReportJournal(true)">Сформировать</VaButton>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-if="et[reportType]>0"
              >
              Запрос выполнен за {{et[reportType]}} {{wordforcount(reportLength.journal,['секунду', 'секунды', 'секунд'])}}. Всего в выборке: {{ reportLength.journal }} {{ wordforcount(reportLength.journal,['запись', 'записи', 'записей']) }}
            </span>
            <span
              color="secondary"
              border="left"
              class="mb-2"
              v-else-if="loading===true"
              >
                Запрос выполняется...
          </span>
            </div>
            <VaDataTable
              v-if="reportLength.journal>0"
              :items="reportJournal"
              :columns="columns.journal"
              sticky-header
              hoverable
              :loading="loading"
            >
              <template #cell(openDate)="{ rowData }">{{ new Date(rowData.openDate.split(" ")[0]).toLocaleDateString() }}</template>
              <template #cell(discharge_date)="{ rowData }">{{ new Date(rowData.discharge_date.split(" ")[0]).toLocaleDateString() }}</template>
              <template #cell(diagnosis)="{ rowData }">
                <VaPopover placement="right" v-if="rowData.diagnosis.length>12" style="max-width:50vw">
                  {{ rowData.diagnosis.slice(0,10) }}...
                  <template #body>{{rowData.diagnosis}}</template>
                </VaPopover>
                <span v-else>{{ rowData.diagnosis }}</span>
              </template>
              <template #cell(operationName)="{ rowData }">
                <VaPopover placement="right" v-if="rowData.operationName.length>12" style="max-width:50vw">
                  {{ rowData.operationName.slice(0,10) }}...
                  <template #body>{{rowData.operationName}}</template>
                </VaPopover>
                <span v-else>{{ rowData.operationName }}</span>
              </template>
              <template #cell(xray_image)="{ rowData }">
                <VaPopover placement="right" v-if="rowData.xray_image.image" style="max-width:50vw">
                  {{ rowData.xray_image.date }}
                  <template #body>
                    <p>Загрузил: {{ rowData.xray_image.user_full_name }}</p>
                    <p>Описание: {{ rowData.xray_image.comment.length > 0 ? rowData.xray_image.comment.length : 'отсутствует' }}</p>
                  </template>
                </VaPopover>
                <span v-else>Нет</span>
              </template>
              <template #cell(patient_sex)="{ rowData }">{{ sex_str[rowData.patient_sex] }}</template>
              <template #cell(patient_villager)="{ rowData }"> {{ rowData.patient_villager === "True" ? 'Да' : "Нет" }} </template>
              <template #cell(paid)="{ rowData }"> {{ rowData.paid == "True" ? 'Платный' : "Нет" }} </template>
            </VaDataTable>

            <VaPagination
              v-if="reportLength.journal>0 && pages.journal > 1"
              v-model="filter.journal.page"
              :pages="pages.journal"
              :visible-pages="3"
              buttons-preset="secondary"
              class="justify-center sm:justify-start"
            />
        </VaCardContent>
      </Transition>


  </VaCard>
</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';
import debounce from 'lodash.debounce'
import { formatDate } from "@/common/rcfx";
import { join } from "lodash";

export default {
    components: { RouterLink, PatientProfile },
    data() {
        return {
            loading: false,
            ownfilter: [
              { label: "Все", value: false },
              { label: "Только мои", value: true },
            ],
            formOptions: [
              { label: "Табличная форма", value: false },
              { label: "Печатная форма", value: true },
            ],            
            options: [
              { text: "Не важно", value: -1 },
              { text: "Да", value: 1 },
              { text: "Нет", value: 0 },
            ],
            sexoptions: [
              { text: "Не важно", value: -1 },
              { text: "Мужской", value: "m" },
              { text: "Женский", value: "f" },
            ],
            columns: {
              visits:
              [
                { key: "id", label: "Номер", sortable: true, width: 1 },
                { key: "visitDate", label: "Дата приема", sortable: true, width: 10 },
                { key: "diagnosis", label: "Диагноз", sortable: true, width: 10 },
                { key: "mkb10", label: "МКБ-10", sortable: true, width: 10 },
                { key: "patient_full_name", label: "ФИО пациента", sortable: true, width: 10 },
                { key: "patient_age_at_visit", label: "Возраст", sortable: true, width: 10 },
                { key: "patient_sex", label: "Пол", sortable: true, width: 10 },
                { key: "patient_villager", label: "Сельский житель", sortable: true, width: 10 },
                { key: "paid", label: "Оплата", sortable: true, width: 10 },
              ],
              discharge:
              [
                { key: "id", label: "Номер", sortable: true, width: 1 },
                { key: "discharge_date", label: "Дата выписки", sortable: true, width: 10 },
                { key: "diagnosis", label: "Диагноз", sortable: true, width: 10 },
                // { key: "mkb10", label: "МКБ-10", sortable: true, width: 10 },
                { key: "patient_full_name", label: "ФИО пациента", sortable: true, width: 10 },
                { key: "patient_age_at_discharge", label: "Возраст", sortable: true, width: 10 },
                { key: "patient_sex", label: "Пол", sortable: true, width: 10 },
                { key: "patient_villager", label: "Сельский житель", sortable: true, width: 10 },
                { key: "paid", label: "Оплата", sortable: true, width: 10 },
              ],
              journal:
              [
                { key: "id", label: "Номер", sortable: true, width: 1 },
                { key: "openDate", label: "Дата поступления", sortable: true, width: 10 },
                { key: "discharge_date", label: "Дата выписки", sortable: true, width: 10 },
                { key: "clinic_days", label: "Кол-во койко-дней", sortable: true, width: 10 },
                { key: "patient_full_name", label: "ФИО пациента", sortable: true, width: 10 },
                { key: "diagnosis", label: "Диагноз", sortable: true, width: 10 },
                { key: "operationCode", label: "Код операции", sortable: true, width: 10 },
                { key: "operationCodeKSG", label: "Код КСГ", sortable: true, width: 10 },
                { key: "operationName", label: "Название операции", sortable: true, width: 10 },
                { key: "xray_image", label: "Дата снимка", sortable: true, width: 10 },
                { key: "doctor_full_name", label: "Лечащий врач", sortable: true, width: 10 },
                { key: "operator_full_name", label: "Оператор", sortable: true, width: 10 },
                { key: "patient_age_at_discharge", label: "Возраст", sortable: true, width: 10 },
                { key: "patient_sex", label: "Пол", sortable: true, width: 10 },
                { key: "patient_villager", label: "Сельский житель", sortable: true, width: 10 },
                { key: "paid", label: "Оплата", sortable: true, width: 10 },
              ],
            },
            itemsPerPage: 20,
            pages:1,
            page:1,
            visits: [],
            selectedVisit: 0,
            patient: {},
            filter: 'open',
            myown: false,
            showVisitModal: false,
            printForm: true,
            printWindow: false,
            prev: String,
            next: String,
            query: '',
            filter: {
              visits: {
                sex: -1,
                paid: -1,
                villager: -1,
                dateMin: new Date(2024,3,1),
                dateMax: new Date(),
                age:[18,70],
                page: 1,
              },
              discharge: {
                sex: -1,
                paid: -1,
                villager: -1,
                dateMin: new Date(2024,3,1),
                dateMax: new Date(),
                age:[18,70],
                page: 1,
              },
              journal: {
                code: "",
                ksg: "",
                sex: -1,
                paid: -1,
                villager: -1,
                dateMin: new Date(2024,3,1),
                dateMax: new Date(),
                age:[18,70],
                page: 1,
              },
            },
            codes: {},
            reportVisits: {},
            reportDischarge: {},
            reportJournal: {},
            reportLength: {
              visits: 0,
              discharge: 0,
              journal: 0,
            },
            page: {
              visits: 1,
              discharge: 1,
              journal: 1,
            },
            pages: {
              visits: 0,
              discharge: 0,
              journal: 0,
            },
            reportType: 0,
            et: [0,0,0],
            sex_str: {"m":"М", "f":"Ж"},
            time: {
              start: '',
              end: '',
            },
            start:'',
            end:'',
            slideDirection: "slide-left",
          };
    },
    computed: {
      pageVisits() {
        return this.filter.visits.page
      },
      pageDischarge() {
        return this.filter.discharge.page
      },
      pageJournal() {
        return this.filter.journal.page
      },
      ownfiltered() {
        if (this.filter === 'all') {
          return this.filtered
        }
        if (this.filter === 'myown') {
          return this.filtered.filter(item => item.user === this.$store.state.app.account.profile.id)
        }
      },
      tooShortQuery() {
        return this.query.length !== 0 && this.query.length<3 ? true : false 
      },
      age() {
        return this.filter.age
      }
    },
    watch: {
      pageVisits(value) {
        this.getReportVisits()
      },
      pageDischarge(value) {
        this.getReportDischarge()
      },
      pageJournal(value) {
        this.getReportJournal()
      },
      end(value) {
        if (value>0) {
          this.et[this.reportType] = (this.end - this.start)/1000
        }
      },
      reportType(newValue,oldValue) {
        if (newValue===2) {
          this.getCodes()
        }
        if (newValue < oldValue) {
          this.slideDirection = "slide-right"
        } else {
          this.slideDirection = "slide-left"
        }
      },
      query: function (newValue) {
        if (!this.tooShortQuery) {
          this.debouncedSearch()
        }
      },
      myown() {
        this.getVisits(true,this.query)
      },
      page(value) {
        this.getVisits(false,this.query)
      }
    },
    methods: {
      wordforcount(value, words) {
        value = Math.abs(value) % 100; 
        var num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num == 1) return words[0]; 
        return words[2];
      },
      buildQuery() {
        var filter
        if (this.reportType===0) {
          filter = this.filter.visits
        } else if (this.reportType === 1) {
          filter = this.filter.discharge
        } else if (this.reportType === 2) {
          filter = this.filter.journal
        }
        var q = []
        q.push("dateMin=" + filter.dateMin.toLocaleDateString())
        q.push("dateMax=" + filter.dateMax.toLocaleDateString())
        q.push("ageMin=" + filter.age[0])
        q.push("ageMax=" + filter.age[1])
        filter.villager >= 0 && q.push("villager=" + filter.villager)
        filter.paid >= 0 && q.push("paid=" + filter.paid)
        filter.code !== "" && q.push("code=" + filter.code)
        filter.ksg !== "" && q.push("ksg=" + filter.ksg)
        typeof(filter.sex)==="string" && q.push("sex=" + filter.sex)
        filter.page >= 0 && q.push("page=" + filter.page)
        console.log(JSON.stringify(q))
        return '?' + q.join('&')
      },
      async print(elid, wait=false) {
        if (wait) {
          await this.showVisitModal && this.$htmlToPaper(elid, null, () => {this.$refs.VisitDetailsModal.hide()});
        }else{
          await this.$htmlToPaper(elid);
        }
      },
      printDocument() {
        this.printWindow = !this.printWindow
      },
      resetSearch() {
        this.query=""
        this.getVisits(true,false)
      },
      debouncedSearch: debounce(function () {
              this.getVisits(true, this.query);
            }, 600),
      getReportVisits(resetpage=false) {
        resetpage && (this.filter.visits.page=1)
        this.end = 0
        this.start = new Date().getTime()
        this.loading = true
        axiosInstance
        .get('report/visits' + this.buildQuery())
        .then(response => {
          this.end = new Date().getTime()
          var data = response.data
          this.pages.visits = Math.ceil(data.count / 20)
          // this.page.visits = 1
          this.reportLength.visits = data.count
          this.reportVisits = data.results
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
      },
      getReportDischarge(resetpage=false) {
        resetpage && (this.filter.discharge.page=1)
        this.end = 0
        this.start = new Date().getTime()
        this.loading = true
        axiosInstance
        .get('report/discharge' + this.buildQuery())
        .then(response => {
          this.end = new Date().getTime()
          var data = response.data
          this.pages.discharge = Math.ceil(data.count / 20)
          // this.page.discharge = 1
          this.reportLength.discharge = data.count
          this.reportDischarge = data.results
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
      },
      getReportJournal(resetpage=false) {
        resetpage && (this.filter.journal.page=1)
        this.end = 0
        this.start = new Date().getTime()
        this.loading = true
        axiosInstance
        .get('report/journal' + this.buildQuery())
        .then(response => {
          this.end = new Date().getTime()
          var data = response.data
          this.pages.journal = Math.ceil(data.count / 20)
          this.page.journal = 1
          this.reportLength.journal = data.count
          this.reportJournal = data.results
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
      },
      getCodes() {
        this.loading = true
        axiosInstance
        .get('report/codes')
        .then(response => {
          var data = response.data
          this.codes = data.results
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })

      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
    },
}

</script>
<style>
.slide-left-leave-active,
.slide-right-leave-active {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-left-enter-active,
.slide-right-enter-active {
  margin-top:20px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(1200px);
}
.slide-left-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-1200px);
}
.slide-left-leave-active,
.slide-right-leave-active {
  position:absolute;
}

</style>