<template>
 <VaCard stripe stripe-color="primary" class="rc-fh" style="height:calc(100vh - 70px);overflow-y:scroll">
  <VaCardTitle>Пост медсестры</VaCardTitle>
  <VaCardContent>
    <VaDataTable :items="united" :columns="cols" class="compact" noDataHtml="Стационар пуст" v-if="allDone">
      <template #cell($)="{ rowData }">
        <VaIcon :name="rowData.paid ? 'attach_money' : 'cruelty_free'" :color="rowData.paid ? 'success' : 'primary'"/>
      </template> 
      <template #cell(patient_full_name)="{ rowData, rowIndex }">
        <VaButton preset="plain" class="rc-text-light" @click.before="currentPatient=rowIndex" @click="showPatient=!showPatient">{{ rowData.patient_full_name }}{{ rowData.patient_birthdate ? ', ' + rowData.patient_birthdate.split('-').reverse().join('.') : '' }}</VaButton><br/>
        <span class="rc-text-s">ИБ №{{ rowData.id }}, Палата: {{ rowData.room }}</span><br/>
        <span class="rc-text-s">Поступление: {{ rowData.openDate }}</span><br/>
        <span class="rc-text-s mt-2"><VaChip size="small" :color="rowData.closed ? 'warning' : 'success'">{{ rowData.closed ? 'История болезни закрыта '+rowData.closeDate : 'История болезни открыта' }}</VaChip></span>
      </template>
      <template #cell(prescriptions)="{ rowData }">
        <VaList>
          <VaListItem v-for="(pre, index) in rowData.prescriptions">
            {{ pre.name }} до {{ pre.dateEnd }}
          </VaListItem>
        </VaList>
      </template>
    </VaDataTable>
    <div v-else class="rc-flex v-center" style="padding-top:25%">
      <VaProgressCircle indeterminate style="margin: 0 auto"></VaProgressCircle>
      <div class="rc-flex center">Загрузка...</div>
    </div>
  </VaCardContent>
 </VaCard>
 <VaModal :allowBodyScroll=true v-if="obsWorking" v-model="obsWorking" hideDefaultActions>
  <VaProgressCircle indeterminate style="margin: 0 auto"></VaProgressCircle>
  Работаем...
 </VaModal>

 <VaModal :allowBodyScroll=true v-if="showPatient" v-model="showPatient" size="large" hideDefaultActions :closeButton="true" fixed-layout>
    <div class="rc-text-l mb-2">{{ united[currentPatient].patient_full_name }}</div>
    <span class="mb-2">ИБ №{{ united[currentPatient].id }}, Палата: {{ united[currentPatient].room }}</span><br/>
    <span class="mb-2">Поступление: {{ united[currentPatient].openDate }}</span><br/>
    <span class="rc-text-s mt-2"><VaChip size="small" :color="united[currentPatient].closed ? 'warning' : 'success'">{{ united[currentPatient].closed ? 'История болезни закрыта' : 'История болезни открыта' }}</VaChip></span>

    <VaAlert class="mb-2 noflex" border="left" color="success" v-if="united[currentPatient].observations">
        <VaCardTitle class="rc-text-m">Лист наблюдений</VaCardTitle>
      <VaCardContent>
        <div class="rcflex row pa-2" style="background-color: #fff;border-radius:10px">
          <div class="column rc-lh15 br-1">
            <div class="rcflex center rc-lh15">
              &nbsp
            </div>
            <div class="rcflex center rc-lh15 bb-1 pb-1">
              <i>&nbsp</i>
            </div>
            <div class="rcflex row rc-lh15">
                t, °С
            </div>
            <div class="rcflex row rc-lh15">
              АД
            </div>
            <div class="rcflex row rc-lh15">
              ЧСС, уд. в мин.
            </div>
            <div class="rcflex row rc-lh15">
              Сатурация, %
            </div>
            <div class="rcflex row rc-lh15">
              Жалобы
            </div>
          </div>
          <div></div>

          <div v-if="united[currentPatient].observations.length>0" v-for="item,index in united[currentPatient].observations">
            <div class="column br-1 rc-tc" style="min-width: 70px;">
              <div class="rcflex center rc-lh15">
                {{ item.date.slice(0,-5) }}
                
              </div>
              <div class="rcflex row">
                <div class="column ml-2 mr-2" style="width:100%">
                  <div class="rcflex center rc-lh15 bb-1">
                    <VaIcon size='1.35rem' :color="item.time=='morning' ? 'warning' : 'primary'" :name="item.time=='morning' ? 'light_mode' : 'dark_mode'" class="mb-2"/>
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    {{ item.temp }}
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    {{ item.ap }}
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    {{ item.hbpm }}
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    {{ item.spo2 }}
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    <VaDropdown trigger="hover" placement="left-center" :offset="[10, 0]" class="rcflex">
                            
                      <template #anchor>
                        {{ item.compliant.length > 0 ? 'есть' : 'нет' }}
                      </template>

                      <VaDropdownContent style="background-color:#f8f8f8;border:1px dashed #dddddd">
                        {{ item.compliant ? item.compliant : 'нет' }}
                      </VaDropdownContent>

                    </VaDropdown>
                  </div>
                  <div class="rcflex row center rc-lh15 rc-tr">
                    <VaIcon size='1.2rem' color="primary" name="edit" class="mb-2" @click="obsStartEdit(item.id, index)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="ml-3 rcflex vcenter">Наблюдения отсутствуют</div>
        </div>
        <VaButton class="mt-3" preset="primary" icon="thermometer_add" @click.before="resetObsForm()" @click="showObsDialog=!showObsDialog">Добавить</VaButton>
      </VaCardContent>
    </VaAlert>

    <VaAlert class="mb-2 noflex" border="left" color="success">
      <VaCardTitle class="rc-text-m">Исследования</VaCardTitle>
      <VaCardContent>
      <VaDataTable
        :columns="testsLabels" 
        :items="united[currentPatient].tests"
        striped
        clickable
        noDataHtml="Исследования не назначались"
        >
        <template #cell(type)="{ rowData }">
          {{ rowData.planDate }}<br/>
          {{ testTypes[rowData.type] }}
          <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
        </template>
        <template #cell(taken)="{ rowData }">
          <div v-if="rowData.taken">
            <div v-if="new Date(rowData.factDate.split(' ')[0].split('.').reverse().join('-')) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
              <VaChip flat icon="warning" color="secondary">Взят: {{rowData.factDate}}</VaChip>
              <p class="rc-text-s">С опозданием</p>
            </div>
            <div v-else>
              <VaChip flat icon="done" color="success">Взят: {{rowData.factDate}}</VaChip>
              <VaChip v-if="rowData.result!=''" flat icon="done" color="success">Внесен результат</VaChip>
              <VaChip v-else="rowData.result!=''" flat icon="schedule" color="secondary">Ожидается результат</VaChip>
            </div>
          </div>
          <div v-else>
            <div v-if="new Date(today) &gt; new Date(rowData.planDate.split('.').reverse().join('-'))">
              <VaChip flat icon="error" color="danger">Дата забора прошла</VaChip>
            </div>
            <div v-else>
              <VaChip flat icon="schedule" color="secondary">Запланирован</VaChip>
            </div>
          </div>
        </template>
        <template #cell(actions)="{ rowData, rowIndex }">
          <VaButton :disabled="united[currentPatient].closed" :loading="takingTest[rowIndex]" v-if="!rowData.taken" icon="labs" color="success" preset="secondary" @click="takeTest(rowData.id, rowIndex, rowData.type, true)"/>
          <VaButton :disabled="united[currentPatient].closed || rowData.result!=''" :loading="takingTest[rowIndex]" v-else icon="cancel" color="danger" preset="secondary" @click="takeTest(rowData.id, rowIndex, rowData.type, false)"/>
        </template>
      </VaDataTable>
    </VaCardContent>
    </VaAlert>

    <VaAlert class="mb-2 noflex" border="left">
      <VaCardTitle class="rc-text-m">Назначения</VaCardTitle>
      <VaCardContent>
      <VaDataTable
        :columns="prescriptionsLabels"
        :items="united[currentPatient].prescriptions"
        :loading="prescriptionsLoading"
        striped
        clickable
        noDataHtml="Назначений пока нет"
        >
        <template #cell(name)="{ rowData }">
          <div class="rcflex row">
            <div class="rcflex column" style="line-height:1.3rem">
              <p>{{ rowData.name }}, {{ rowData.dosage }}</p>
              <p>{{ rowData.method }} - {{ rowData.freq }} рвд</p>
              <p>{{ rowData.dateStart }} - {{ rowData.dateEnd }}</p>
              <VaAlert v-if="rowData.comment" color="#FFFFFF" icon="feedback" class="mb-2">{{ rowData.comment }}</VaAlert>
            </div>
          </div>
        </template>
        <template #cell(take)="{ rowData }">
          <div class="rcflex row">
          <div v-for="item,index in rowData.fact">
            <div class="mr-3 column">
            <div style="line-height:1.5rem" class="rcflex center">{{ formatDate(new Date(parseInt(index)), 'short') }}</div>
              <div v-for="f, logIndex in rowData.fact[index]" class="rcflex center">
                          <VaDropdown trigger="hover" placement="left-center" :offset="[10, 0]" class="rcflex">
                            
                            <template #anchor>
                              <VaButton 
                              :disabled="united[currentPatient].closed || ( Number(new Date().getTime()) - index < 0)"
                              @click.before="!f.datetime && !f.missed ? addLogRecord(rowData.id, rowData.medhistory, rowData.name, index, logIndex) : ''"
                              @click="f.datetime && removeLogRecord(f.id, f.prescription, rowData.medhistory, index, logIndex)" 
                              class="mr-2" 
                              :icon="f.datetime ? 'done' : f.missed ? 'error' : 'schedule'" 
                              :preset="!f.datetime && !f.missed ? 'plainOpacity' : 'plain'" 
                              :color="f.datetime ? 'success' : f.missed ? 'warning' : 'secondary'"/>
                            </template>

                            <VaDropdownContent style="background-color:#f8f8f8;border:1px dashed #dddddd">
                              {{ f.datetime ? 'Исполнено: ' + f.datetime + '\nМедсестра: ' + f.nurse_full_name : f.missed ? 'Просрочено' : 'Запланировано' }}
                            </VaDropdownContent>

                          </VaDropdown>
              </div>
            </div>
          </div>
          </div>
        </template>
      </VaDataTable>
    </VaCardContent>
    </VaAlert>
 </VaModal>

 <VaModal :allowBodyScroll=true v-if="showObsDialog" v-model="showObsDialog" hideDefaultActions :closeButton="true" fixed-layout ref="obsDialog">
  <VaForm class="mt-2 rcflex column" ref="obsForm" immediate>
    <VaCardTitle class="rc-text-m">Добавить запись</VaCardTitle>
    <VaDateInput label="Дата записи" class="mb-2" v-model="obsForm.dateRaw"/>
    <VaButtonToggle :disabled="obsEdit" class="mb-2" v-model="obsForm.time" :options="timeOptions" color="#fff" :toggle-color="obsForm.time=='morning' ? 'warning' : 'primary'" :rules="[(value) => (value == 'morning' || value == 'evening') || 'Нужно выбрать время дня']"/>

      <VaInput v-if="obsEdit" label="Температура, °С" class="mb-2" v-model.lazy="obsForm.temp"/>
      <VaInput v-if="obsEdit" label="АД" class="mb-2" v-model.lazy="obsForm.ap"/>
      <VaInput v-if="obsEdit" label="ЧСС, уд. в мин." class="mb-2" v-model.lazy="obsForm.hbpm"/>
      <VaInput v-if="obsEdit" label="Сатурация, %" class="mb-2" v-model.lazy="obsForm.spo2"/>
      <VaInput v-if="obsEdit" label="Жалобы" class="mb-2" v-model.lazy="obsForm.compliant"/>

      <VaInput v-if="!obsEdit" label="Температура, °С" class="mb-2" v-model.lazy="obsForm.temp" :rules="[(value) => (value && value.length > 0) || 'Нужно ввести показатель']"/>
      <VaInput v-if="!obsEdit" label="АД" class="mb-2" v-model.lazy="obsForm.ap" :rules="[(value) => (value && value.length > 0) || 'Нужно ввести показатель']"/>
      <VaInput v-if="!obsEdit" label="ЧСС, уд. в мин." class="mb-2" v-model.lazy="obsForm.hbpm" :rules="[(value) => (value && value.length > 0) || 'Нужно ввести показатель']"/>
      <VaInput v-if="!obsEdit" label="Сатурация, %" class="mb-2" v-model.lazy="obsForm.spo2" :rules="[(value) => (value && value.length > 0) || 'Нужно ввести показатель']"/>
      <VaInput v-if="!obsEdit" label="Жалобы" class="mb-2" v-model.lazy="obsForm.compliant"/>

    <div class="rc-flex row">
      <VaButton
        preset="primary"
        color="secondary"
        @click.before="obsEdit=false"
        @click="$refs.obsDialog.hide()" 
        >
        {{ obsEdit ? "Назад" : "Отмена"}}
      </VaButton>
      <VaButton
        v-if="!obsEdit"
        class="ml-2"
        preset="primary"
        :disabled="!isValid"
        @click.before="validate() && addObservation()"
        @click="showObsDialog=!showObsDialog" 
        >
        Добавить
      </VaButton>
      <VaButton
        v-if="obsEdit"
        class="ml-2"
        preset="primary"
        color="danger"
        @click.before="deleteObservation()"
        @click="showObsDialog=!showObsDialog" 
        >
        Удалить
      </VaButton>
      <VaButton
        v-if="obsEdit"
        class="ml-2"
        preset="primary"
        :disabled="!isValid"
        @click.before="validate() && patchObservation()"
        @click="$refs.obsDialog.hide()"
        @click.after="obsEdit=!obsEdit"
        >
        Сохранить
      </VaButton>
    </div>
  </VaForm>
 </VaModal>
</template>
<script setup>
  import { testTypes } from "@/common/locale"
  import { formatDate, formatTime } from '@/common/rcfx.js'
  import { VaInnerLoading, useForm } from 'vuestic-ui'
  const { isValid, validate, reset, resetValidation } = useForm('obsForm')
</script>
<script>
import axiosInstance from "../services/api"
import PatientProfile from '@/components/PatientProfile'
import VueQRCodeComponent from 'vue-qrcode-component-v3'
import _ from 'lodash'
import { VaCard, VaCardContent } from "vuestic-ui/web-components"
import { all } from "axios"

Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
}

export default {
    components: { PatientProfile, VueQRCodeComponent },
    data() {
        return {
          obsLabels: [
              { key: "temp", label: "Температура", width: 25 },
              { key: "ap", label: "Артериальное давление", width: 24 },
              { key: "hbpm", label: "ЧСС", width: 24 },
              { key: "spo2", label: "Сатурация", width: 24 },
            ],
          prescriptionsLabels: [
              { key: "name", label: "Препарат, частота, курс", width: "40%" },
              //{ key: "dateStart", label: "Начало", width: 25 },
              { key: "take", label: "Журнал приема", width: "60%" },
              //{ key: "dateEnd", label: "Конец", width: 25 },
            ],
          testsLabels: [
              // { key: "id", width: 1 },
              // { key: "planDate", label: "Дата забора", width: 25 },
              { key: "type", label: "Дата и тип исследования", width: 25 },
              { key: "taken", label: "Статус", width: 24 },
              { key: "actions", label: "Действия", width: 24 },
            ],
          logsLoading: false,
          testsLoading: false,
          takingTest: [],
          prescriptionsLoading: false,
          logsJoined: false,
          refreshLogs: false,
          currentPatient: null,
          showPatient: false,
          showObsDialog: false,
          mhList: [],
          mhIDs: [],
          tests: [],
          logs: [],
          prescriptions: [],
          united: [],
          cols: [
            { key: "$", label: '$' },
            { key: "patient_full_name", label: 'ФИО Пациента', width: 25},
            { key: "doctor_full_name", label: 'Врач' },
            { key: "prescriptions", label: 'Назначения' },
          ],
          today: formatDate(new Date, 'us'),
          observations: {},
          observationsLoading: false,
          // dateList: [],
          // observation add form
          timeOptions: [
            { label: "Утро", value: 'morning' },
            { label: "Вечер", value: 'evening' },
          ],
          obsWorking: false,
          obsEdit: false,
          obsIndex: null,
          obsForUpdate: null,
          obsForm: {
            dateRaw: new Date,
            date: new Date().toLocaleDateString().split('.').reverse().join('-'),
            time: '',
            temp: '',
            ap: '',
            hbpm: '',
            spo2: '',
            compliant: '',
            patient: '',
            user: '',
            medhistory: '',
          },
          graph: [],
          graphic: '',
        }
    },
    computed: {
      dateRaw: function() { return this.obsForm.dateRaw },
      userProfile: function() { return this.$store.state.app.account.profile },
      prescriptionsReady: function() {
        return  !this.prescriptionsLoading && !this.logsLoading ? true : false
      },
      allDone: function() {
        return !this.observationsLoading && !this.testsLoading && this.logsJoined ? true : false
      }
    },
    watch: {
      showObsDialog(newValue) {
        if (newValue === true) {
          this.obsForm.patient = this.mhList[this.currentPatient].patient
          this.obsForm.medhistory = this.mhList[this.currentPatient].id
          this.obsForm.user = this.userProfile.id
          if (!this.obsEdit) {
            // console.log('new')
            this.obsForm.time = (new Date().getHours() > 14) ? "evening" : "morning"
          }
        }
        else if (newValue === false) {
          this.obsEdit = false
          this.obsForm.patient = ''
          this.obsForm.medhistory = ''
        }
      },
      dateRaw(newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.obsForm.date = newValue.toLocaleDateString().split('.').reverse().join('-')
        }
      },
      currentPatient: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          // this.dateList = []

          // let oneDay = 1000 * 3600 * 24
          // let openDate = new Date(this.mhList[newValue].openDate.split(' ')[0].split('.').reverse().join('-')).getTime()
          // let delta = new Date().getTime() - new Date(this.mhList[newValue].openDate.split(' ')[0].split('.').reverse().join('-')).getTime();
          // delta = Math.ceil(delta / oneDay);

          // let obsdate
          // for (let i=0; i<=delta; i++) {
          //   obsdate = openDate + oneDay*i
          //   obsdate = new Date(obsdate).toLocaleDateString()
          //   if (this.observations.find((item) => item.medhistory == this.mhList[newValue].id && item.date == obsdate)) {
          //     this.dateList.push(obsdate)
          //   }
          // }
        }
      },
      prescriptionsReady: function(newValue) {
        if (newValue == true) {
         this.joinLogs(this.prescriptions, this.logs)
        }
      },
      allDone: function(newValue) {
        if (newValue == true) {
         this.joinTables(this.mhList, this.prescriptions, this.tests, this.observations)
        }
      },
      refreshLogs: function(newValue) {
        if (newValue == true) {
          this.united
        }
      }
    },
    methods: {
      resetObsForm() {
        this.obsForm = {
            dateRaw: new Date,
            date: new Date().toLocaleDateString().split('.').reverse().join('-'),
            time: '',
            temp: '',
            ap: '',
            hbpm: '',
            spo2: '',
            compliant: '',
            patient: '',
            user: '',
            medhistory: '',
          }
      },
      drawGraph(obs){
        this.graph = []
        this.graphic = ''
        for (let i=0; i< obs.length; i++) {
          for (let k=0; k< obs[i].length; k++) {
              this.graph.push({date: obs[i][k].date, time: obs[i][k].time, temp: obs[i][k].temp})
          }
        }
        this.graphic += "<div style='display:flex;align-items:flex-end;width:100%;height:150px'>"
        for (let m=0; m < this.graph.length; m++) {
          this.graphic += "<div style='background:green;margin-right:3px;width:5px;height:" + this.graph[m].temp + "%' ></div>"
        }
        this.graphic += "</div>"
        },
      obsStartEdit(obsId, index) {
          this.obsEdit = true
          this.obsForUpdate = obsId
          // let clone = {}
          // console.log('Вот: ' + this.united[this.currentPatient].observations[index])
          for (let key in this.united[this.currentPatient].observations[index]) {
            // console.log(key)
            this.obsForm[key] = this.united[this.currentPatient].observations[index][key]
          }
          // this.obsForm = this.united[this.currentPatient].observations.slice(index, index+1)
          // console.log(this.obsForm)
          // this.obsForm = clone
          this.obsForm.dateRaw = new Date(this.obsForm.date.split('.').reverse().join('-'))
          this.showObsDialog=true
      },
      joinLogs(preObj, logsObj){
        var pretmp = []
        for (let i=0;i<preObj.length;i++) {
          this.prescriptions[i].logs=[]
          for (let e=0;e<logsObj.length;e++) {
            if (logsObj[e].prescription == preObj[i].id) {
                //console.log('match! ' + JSON.stringify(this.prescriptions[i].logs))
                this.prescriptions[i].logs.push(this.logs[e])
              }
          }
        }
        this.logsJoined = true
      },
      makeObservations(mhIdx) {
        let oneDay = 1000 * 3600 * 24
        let openDate = new Date(this.mhList[mhIdx].openDate.split(' ')[0].split('.').reverse().join('-')).getTime()
        let delta = new Date().getTime() - openDate;
        delta = Math.ceil(delta / oneDay);

          let obs = this.observations.filter((item) => item.medhistory == this.mhList[mhIdx].id)
          obs.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          return obs
      },
      joinTables(mhObj, preObj, testsObj, obsObj) {
        
        for (let i=0;i<mhObj.length;i++) {
          var prestmp = []
          var teststmp = []
          //console.log('---Чекаем назначения')
          for (let e=0;e<preObj.length;e++) {
            //console.log('Сравниваю ' + preObj[e].medhistory + ' и ' + mhObj[i].id)
              if (preObj[e].medhistory == mhObj[i].id) {
                //console.log('match!')
                prestmp.push(preObj[e])
              }
            }
          //console.log('---Чекаем анализы')
          for (let e=0;e<testsObj.length;e++) {
          //console.log('Сравниваю ' + testsObj[e].medhistory + ' и ' + mhObj[i].id)
            if (testsObj[e].medhistory == mhObj[i].id) {
              //console.log('match!')
              teststmp.push(testsObj[e])
            }
          }
              this.united.push({
                id: mhObj[i].id,
                patient_full_name: mhObj[i].patient_full_name,
                patient_birthdate: mhObj[i].patient_birthdate,
                doctor_full_name: mhObj[i].doctor_full_name,
                openDate: mhObj[i].openDate,
                closed: mhObj[i].closed,
                closeDate: mhObj[i].closeDate,
                paid: mhObj[i].paid,
                room: mhObj[i].room,
                user: mhObj[i].user,
                patient: mhObj[i].patient,
                prescriptions: prestmp,
                tests: teststmp,
                observations: this.makeObservations(i)
              })
        }
        this.convertLogs(this.united)
      },
      convertLogs(list) {
        var today = new Date(formatDate(new Date, 'ru').split('.').reverse().join('-')).getTime()
        //console.log('today is: ' + today)
        // перебор основного объединенного массива
          for (var i = 0; i < list.length; i++) {
              // i = номер в объединенном массиве
              // e = номер в назначения
              // d = номер даты в графике
              // f = номер записи в журнале исполнений назначений
              if (list[i].prescriptions.length > 0) {
                  // перебор массива назначений для одной записи в объединенном массиве
                  for (var e = 0; e < list[i].prescriptions.length; e++) {
                      var dates = this.makeGraph(list[i].prescriptions[e].dateStart, list[i].prescriptions[e].duration)
                          list[i].prescriptions[e].fact = {}
                          // перебор массива из дат графика приема
                          for (var d = 0; d < dates.length; d++) {
                              var currentDate = new Date(dates[d].split('.').reverse().join('-')).getTime()
                              // перебор массива журнала для одной записи в массиве назначений
                              list[i].prescriptions[e].fact[currentDate] = []
                              var count = 0
                              for (var f = 0; f < list[i].prescriptions[e].logs.length; f++) {
                                  var foundDate = new Date(list[i].prescriptions[e].logs[f].datetime.split(' ')[0].split('.').reverse().join('-')).getTime()
                                  if (currentDate === foundDate) {
                                    list[i].prescriptions[e].fact[currentDate].push(list[i].prescriptions[e].logs[f])
                                    count++
                                  }

                              }
                              // дописываем неисполненные назначения пустыми объектами
                              if (count<list[i].prescriptions[e].freq) {
                                  var delta = parseInt(list[i].prescriptions[e].freq) - count
                                  for (var k=0;k<delta;k++){
                                    var missed = currentDate < today ? true : false
                                    list[i].prescriptions[e].fact[currentDate].push({missed: missed})
                                  }
                                }
                          }
                  }
              }
          }
      },
      makeGraph(start, duration) {
        var result = []
        for (let i=0; i<duration; i++) {
          var a = start.split('.').reverse().join('-')
          var b = new Date(a).addDays(i)
          result.push(formatDate(b,'ru'))
        }
        return result
      },
      takeTest(testId, testIndex,  testType, status) {
        this.takingTest[testIndex] = true
        var message = status ? 'Исследование ' + testTypes[testType] + ' исполнено' : 'Исследование ' + testTypes[testType] + ' отменено'
        var color = status ? 'success' : "warning"
        axiosInstance
            .patch('/medhistory/test/' + testId, {taken: status, result: ''})
            .then(response => {
              console.log('test Index = '+testIndex)
                this.united[this.currentPatient].tests[testIndex].taken = status
                this.united[this.currentPatient].tests[testIndex].factDate = response.data.factDate
                this.$vaToast.init({ message: message, color: color, position: 'bottom-right'})
                var recipient = this.united[this.currentPatient].user
                var payload = {
                  title: "Исследование",
                  body: 'Заказанный вами "' + testTypes[testType] + '" для пациента ' + this.united[this.currentPatient].patient_full_name + ' взят ' + response.data.factDate +'. Исполнитель: ' + this.$store.state.app.account.profile.full_name,
                }
                this.addEvent("comment", payload, recipient)
                this.takingTest[testIndex] = false
            })
            .catch(error => {
              this.takingTest[testIndex] = false
              console.log('Test get error: ' + error);
            })
      },
      getLogs() {
        axiosInstance
            .get('/medhistory/prescriptionlogs?medhistory=' + this.mhIDs.toString())
            .then(response => {
              this.logs = response.data.results
            })
            .catch(error => {
              console.log('Logs get error: ' + error);
            })
            .finally(() => {
              this.logsLoading = false
            })
      },
      updateFact(medhistory, prescriptionId, logrecord=false, dateIndex, logIndex) {
        console.log(logrecord)
        var uIndex = this.united.findIndex((item) => item.id == medhistory)
        var pIndex = this.united[uIndex].prescriptions.findIndex((item) => item.id == prescriptionId)
        delete this.united[uIndex].prescriptions[pIndex].fact[dateIndex][logIndex]
        this.united[uIndex].prescriptions[pIndex].fact[dateIndex][logIndex] = logrecord
      },
      removeLogRecord(logId, prescriptionId, medhistoryId, dateIndex, logIndex){
        axiosInstance
            .delete(`/medhistory/prescriptionlogs/remove/`+logId)
            .then(() => {
              this.updateFact(medhistoryId, prescriptionId, {missed: false}, dateIndex, logIndex)
              this.$vaToast.init({ message: 'Исполнение отменено', color: 'warning', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Prescription LOG post error: ' + error);
            })
      },
      addLogRecord(prescriptionId, medhistoryId, prescriptionName, dateIndex, logIndex) {
        var now = new Date
        
        var record = {
            datetime: formatDate(now, 'us') + ' ' + formatTime(now),
            prescription: prescriptionId,
            executedBy: this.userProfile.id,
            medhistory: medhistoryId,
        }
        axiosInstance
            .post(`/medhistory/prescriptionlogs/new`, record)
            .then(response => {
              this.updateFact(medhistoryId, prescriptionId, response.data, dateIndex, logIndex)
              this.$vaToast.init({ message: 'Назначение ' + prescriptionName + ' исполнено ' + record.datetime, color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              console.log('Prescription LOG post error: ' + error);
            })
      },
      getTests() {
        this.testsLoading = true
        axiosInstance
            .get('/medhistory/tests?medhistory=' + this.mhIDs.toString())
            .then(response => {
              this.tests = response.data.results
              //this.joinTables(this.mhList, this.tests)
            })
            .catch(error => {
              console.log('Test get error: ' + error);
            })
            .finally(() => {
              this.testsLoading = false
            })
      },
      getPrescriptions() {
      this.prescriptionsLoading = true
      axiosInstance
          .get('/medhistory/prescriptions?medhistory=' + this.mhIDs.toString())
          .then(response => {
            this.prescriptions = response.data.results
            //this.joinTables(this.mhList, this.prescriptions)
          })
          .catch(error => {
            console.log('Test get error: ' + error);
          })
          .finally(() => {
            this.prescriptionsLoading = false
          })
      },
      getObservations() {
        this.observationsLoading = true
        axiosInstance
            .get('/observations')
            .then(response => {
              this.observations = response.data.results
            })
            .catch(error => {
              console.log('Observations get error: ' + error);
            })
            .finally(() => {
              this.observationsLoading = false
          })
      },
      sortObservations() {
        this.united[this.currentPatient].observations.sort(function(a,b) {
          if (new Date(a.date.split('.').reverse()).getTime() > new Date(b.date.split('.').reverse()).getTime()) {
            return 1
          } else if (new Date(a.date.split('.').reverse()).getTime() < new Date(b.date.split('.').reverse()).getTime()) {
            return -1
          } else if (new Date(a.date.split('.').reverse()).getTime() == new Date(b.date.split('.').reverse()).getTime()) {
            return (a.time < b.time) ? 1 : -1
          }
        })
      },
      addObservation() {
        this.obsWorking = true
        this.obsForm.temp = this.obsForm.temp.replace(',','.');
        console.log('Валидация прошла, начинаем добавление наблюдения');
        axiosInstance
            .post('/observations', this.obsForm)
            .then(response => {
              let result = response.data
              this.observations.push(result)
              this.united[this.currentPatient].observations = this.makeObservations(this.currentPatient)
              this.$vaToast.init({ message: 'Показания добавлены', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              if (error.response && error.response.status == 400 && error.response.data.non_field_errors) {
                let timeOfRecord = this.obsForm.time == "morning" ? "Утренние " : "Вечерние "
                this.$vaToast.init({title: 'Обнаружен дубликат', message: timeOfRecord + 'показания на дату ' + this.obsForm.date.split('-').reverse().join('.') + ' уже имеются в карте', color: 'warning', position: 'bottom-right'})
              }else{
                this.$vaToast.init({ message: 'Показания не добавлены, введите корректные данные', color: 'warning', position: 'bottom-right'})
                console.log('Observations add error: ' + error);
              }
            })
            .finally(() => {
              this.sortObservations()
              this.obsWorking = false
          })
      },
      patchObservation() {
        this.obsWorking = true
        // this.obsForm.temp = this.obsForm.temp.replace(',','.');
        axiosInstance
            .patch('/observations/'+this.obsForm.id, this.obsForm)
            .then(response => {
              let result = response.data
              console.log(result)
              let index = this.observations.findIndex((item) => item.id == this.obsForm.id)
              this.observations[index] = response.data
              // this.united[this.currentPatient].observations.find((item) => item.id == this.obsForm.id) = response.data
              this.united[this.currentPatient].observations = this.makeObservations(this.currentPatient)
              this.$vaToast.init({ message: 'Показания изменены', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              this.$vaToast.init({ message: 'Показания не изменены, введите корректные данные', color: 'warning', position: 'bottom-right'})
              console.log('Observations patch error: ' + error);
            })
            .finally(() => {
              // this.obsForm.date = this.obsForm.date.split('-').reverse().join('.')
              this.sortObservations()
              this.obsWorking = false
            })
      },
      deleteObservation() {
        this.obsWorking = true
        // this.obsForm.temp = this.obsForm.temp.replace(',','.');
        axiosInstance
            .delete('/observations/'+this.obsForm.id)
            .then(response => {
              console.log('Observation deleted')
              // this.observations.push(result)
              this.united[this.currentPatient].observations = this.united[this.currentPatient].observations.filter((item) => item.id !== this.obsForm.id)
              this.observations = this.observations.filter((item) => item.id !== this.obsForm.id)
              this.$vaToast.init({ message: 'Показания удалены', color: 'success', position: 'bottom-right'})
            })
            .catch(error => {
              this.$vaToast.init({ message: 'Показания не удалены, повторите попытку позже', color: 'warning', position: 'bottom-right'})
              console.log('Observations delete error: ' + error)
            })
            .finally(() => {
              this.obsWorking = false
            })
      },
      getMedhistoryList() {
        axiosInstance
            .get(`/medhistory/list`)
            .then(response => {
                var results = response.data.results
                console.log(results.length)
                this.mhList = _.sortBy(results,['room'])
                for (let i=0; i<this.mhList.length; i++) {
                  this.mhIDs.push(this.mhList[i].id)
                }
                if (results.length>0) {
                  this.getObservations()
                  this.getTests()
                  this.getLogs()
                  this.getPrescriptions()
                }else{
                  this.logsJoined = true
                }
              })
            .catch(error => {
              console.log('Templates: error ' + error);
        })
            .finally(() => {
              console.log('Templates: Request completed')
              //this.getPatient(this.medhistory.patient)
        });
      },
      addEvent(type,payload,user) {
        console.log('Trying to add a Event')
        var event = {
          sent:0,
          event: type,
          payload: JSON.stringify(payload),
          recipient: user,
        }
        console.log(JSON.stringify(event))

        axiosInstance
            .post('/events/new', event)
            .then(response => {
              const message = 'Evend sent ok: ' + JSON.stringify(response)
              console.log(message);
        })
            .catch(error => {
            console.log('Event sent failed: ' + JSON.stringify(error))
        })
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getMedhistoryList()
    }
}
</script>